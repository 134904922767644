import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useErrorsAndWarnings } from '../../hooks/ErrorsAndWarnings.js';
import { ActionsWidget } from '../do/ActionsWidget.js';
import { CurrentReadingPlan } from '../plans/read/CurrentReadingPlan.js';
import { PrayerSnapshot } from '../pray/PrayerSnapshot.js';
import { ShxHelpText } from '../common/ShxHelpText.js';
import { TutorialMessages } from './TutorialMessages.js';
import { ServerMessages } from './ServerMessages.js';

export const Home = () => {
  const { AlertUI, addErrorMessage } = useErrorsAndWarnings();

  return (
    <>
      <ServerMessages />
      <TutorialMessages />

      <Container fluid className="page-main-container">
        <AlertUI />

        <ShxHelpText pageName="home" textName="homeScreen" firstLevel variant="success" />
        <ShxHelpText pageName="home" textName="welcomeMsg" firstLevel variant="warning" />
        <ShxHelpText pageName="home" textName="discussionGroup" />

        <Row>
          <Col xs="12" sm="6" lg="2">
            <ShxHelpText pageName="home" textName="actionsWidget" className="mb-0" />
            <ActionsWidget
              showTitle={true}
              showDetails={false}
              setErrorMessage={addErrorMessage}
              showLongNames={false}
            />
          </Col>
          <Col xs="12" sm="6" lg="3" className="mt-5 mt-sm-0">
            <ShxHelpText pageName="home" textName="currentReadingPlan" className="mb-0" />
            <CurrentReadingPlan showTitle={true} />
          </Col>
          <Col xs="12" sm="12" lg="7" className="mt-5 mt-lg-0">
            <ShxHelpText pageName="home" textName="prayerSnapshot" className="mb-0" />
            <PrayerSnapshot />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
