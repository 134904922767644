import React from 'react';
import { Container } from 'react-bootstrap';
import i18n from '../../i18n.js';
import { ServerMessage } from '@soulhx/fs-common';
import { useTranslation } from 'react-i18next';
import { ShxServerMessage } from './helpers/ShxServerMessage.js';
import { useGetAllServerMessagesQuery } from '../../services/CommsService.js';

interface ResourceBundle {
  lang: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  resources: any;
}

interface Message {
  id: string;
  dismissible: boolean;
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
}

interface ServerData {
  messages: Message[];
  bundles: ResourceBundle[];
}

const parseServerMessages = (data: ServerMessage[]): ServerData => {
  const serverData: ServerData = {
    messages: [],
    bundles: [],
  };

  for (const msg of data) {
    serverData.messages.push({ id: msg.id, dismissible: msg.dismissible, variant: msg.variant });

    for (const t of msg.text) {
      const index = serverData.bundles.findIndex((b) => b.lang === t.lang);
      if (index >= 0) {
        serverData.bundles[index].resources[msg.id] = t.msg;
      } else {
        serverData.bundles.push({ lang: t.lang, resources: { [msg.id]: t.msg } });
      }
    }
  }

  return serverData;
};

interface IServerMessages {
  cancelDismiss?: boolean;
}
export const ServerMessages = ({ cancelDismiss = false }: IServerMessages) => {
  const { data } = useGetAllServerMessagesQuery();
  const { t } = useTranslation(['serverMessages']);

  if (!data) return <></>;

  const messages = parseServerMessages(data);
  for (const b of messages.bundles) {
    i18n.addResourceBundle(b.lang, 'serverMessages', b.resources);
  }

  return (
    <Container fluid>
      {messages.messages.map((m) => (
        <ShxServerMessage
          key={`serverMsg-${m.id}`}
          id={m.id}
          dismissible={cancelDismiss ? false : m.dismissible}
          variant={m.variant}
        >
          {t(`serverMessages:${m.id}`)}
        </ShxServerMessage>
      ))}
    </Container>
  );
};
