import React, { ReactNode } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ErrorResponse } from '@soulhx/fs-common';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { ApiErrorType } from '../../../dm/errors.js';

interface IFormattedCustomError {
  er: ErrorResponse;
}
export const FormattedCustomError = ({ er }: IFormattedCustomError) => {
  const { t } = useTranslation();

  return (
    <div key={uuidv4()}>
      <p className="fw-bold">{t('errorMessages.formattedCustom.title')}</p>
      <ul>
        {er.errors.map((item) => (
          <li key={`err-msg-detail-${uuidv4()}`}>
            {item.message} {item.field && `; ${item.field}`}
          </li>
        ))}
      </ul>
    </div>
  );
};

interface IErrorLoadingDataMessage {
  errors: ApiErrorType[];
}
export const ErrorLoadingDataMessage = ({ errors }: IErrorLoadingDataMessage) => {
  const { t } = useTranslation();
  const messages: JSX.Element[] = [];

  for (const err of errors) {
    if (!err) {
      continue;
    }

    if ('status' in err) {
      messages.push(
        <div key={uuidv4()}>
          {t('errorMessages.errorLoadingData.statusCode', { statusCode: (err as FetchBaseQueryError).status })}
        </div>
      );
    } else if ('data' in err) {
      messages.push(
        <div key={uuidv4()}>
          <FormattedCustomError er={err.data as ErrorResponse} />
        </div>
      );
    } else if ('message' in err) {
      messages.push(<div key={uuidv4()}>{(err as Error).message}</div>);
    } else {
      messages.push(
        <div key={uuidv4()}>{(err as SerializedError).message || t('errorMessages.errorLoadingData.noData')}</div>
      );
    }
  }

  return (
    <Alert variant="danger">
      <Alert.Heading>{t('errorMessages.errorLoadingData.title')}</Alert.Heading>
      <div>{t('errorMessages.errorLoadingData.message')}</div>
      {messages}
    </Alert>
  );
};

interface IClientSideError {
  children: ReactNode;
}
export const ClientSideErrorLoading = ({ children }: IClientSideError) => {
  const { t } = useTranslation();

  return (
    <Alert variant="danger">
      <Alert.Heading>{t('errorMessages.clientSide.title')}</Alert.Heading>
      <div>{t('errorMessages.clientSide.message')}</div>
      <div>{children}</div>
    </Alert>
  );
};
