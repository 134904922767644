import React, { ReactNode, useState } from 'react';
import { useCreateEntryMutation } from '../../../services/ActionsService.js';
import { BaseActionEntry } from '@soulhx/fs-common';
import { Button } from 'react-bootstrap';
import { TooltipElement } from '../ui/TooltipElement.js';

export interface IActionButton {
  id: string;
  children: ReactNode;
  toolTip?: string;
}
export const ActionButton = ({ id, children, toolTip }: IActionButton) => {
  const [createActionEntry] = useCreateEntryMutation();
  const [buttonState, setButtonState] = useState<'success' | 'danger' | undefined>(undefined);

  return (
    <TooltipElement tooltip={toolTip}>
      <Button
        variant="outline-primary"
        aria-label={toolTip}
        className={buttonState === undefined ? 'border-0 bg-white' : `border-0 bg-${buttonState}-subtle`}
        onClick={() => {
          const newItem: BaseActionEntry = {
            actionType: id,
            completed: true,
          };

          createActionEntry(newItem)
            .unwrap()
            .then(() => {
              setButtonState('success');

              setTimeout(() => setButtonState(undefined), 3000);
            })
            .catch(() => {
              setButtonState('danger');

              setTimeout(() => setButtonState(undefined), 3000);
            });
        }}
      >
        {children}
      </Button>
    </TooltipElement>
  );
};
