import React, { useState } from 'react';
import { ReadingPlanDay } from '@soulhx/fs-common';
import { useTranslation } from 'react-i18next';
import { SHXButton, StandardButton } from '../../../common/ui/buttons/index.js';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { updateDateForReadingPlan } from '../../../../stores/UISlice.js';
import { getDateRangeForReadingPlans } from '../helper-functions.js';
import { Modal } from 'react-bootstrap';
import { PlanCalendarView } from '../../PlanCalendarView.js';

interface ICRPHeader {
  showTitle: boolean;
  data: ReadingPlanDay[];
  dateToShow: DateTime;
  toastUI: JSX.Element;
}
export const CurrentReadingPlanHeader = ({ showTitle, data, dateToShow, toastUI }: ICRPHeader) => {
  const { t } = useTranslation(['plan']);
  const dispatch = useDispatch();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  const [earliestDate, latestDate] = getDateRangeForReadingPlans(data);

  return (
    <>
      {toastUI}
      {showTitle && <h5 className="text-center">{t('plan:current.heading', { count: data.length })}</h5>}
      <h5 className="d-flex justify-content-center">
        <StandardButton
          type="left"
          onClick={() => {
            const newDate = dateToShow.minus({ day: 1 });
            dispatch(updateDateForReadingPlan(newDate.toISODate()!));
          }}
          disabled={dateToShow.startOf('day') <= earliestDate.startOf('day')}
        />

        <SHXButton variant="outline-dark" onClick={() => setShowDatePicker(true)}>
          {dateToShow.toLocaleString()}
        </SHXButton>

        <StandardButton
          type="right"
          onClick={() => {
            const newDate = dateToShow.plus({ day: 1 });
            dispatch(updateDateForReadingPlan(newDate.toISODate()!));
          }}
          disabled={dateToShow.startOf('day') >= latestDate.startOf('day')}
        />
      </h5>
      <Modal show={showDatePicker} onHide={() => setShowDatePicker(false)}>
        <Modal.Header closeButton>{t('plan:current.chooseDateHeading')}</Modal.Header>
        <Modal.Body>
          <PlanCalendarView dateToShow={dateToShow} clickHandler={() => setShowDatePicker(false)} />
        </Modal.Body>
      </Modal>
    </>
  );
};
