import React from 'react';
import { CheckSquareFill } from 'react-bootstrap-icons';

interface ITabTitle {
  tabName: string;
  completed: boolean;
  isSelected: boolean;
}
export const TabTitle = ({ tabName, completed, isSelected }: ITabTitle) => {
  const clazz = completed ? 'text-success' : isSelected ? 'text-primary fw-bold' : 'text-secondary';

  return (
    <span className={clazz}>
      {tabName}
      {completed && <CheckSquareFill className="ms-2" />}
    </span>
  );
};
