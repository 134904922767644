import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  CreateUser,
  PwdChangeRequest,
  PwdChangeResponse,
  PwdChangeSubmit,
  PwdChangeSubmitResponse,
  RegisterUser,
} from '@soulhx/fs-common';

interface RegistrationResponse {
  success: boolean;
}

export const registrationApi = createApi({
  reducerPath: 'registration',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/auth' }),
  tagTypes: ['registration'],
  endpoints: (builder) => ({
    preregister: builder.mutation<RegistrationResponse, RegisterUser>({
      query: (newUser) => ({
        url: '/register',
        method: 'POST',
        body: newUser,
      }),
    }),
    createUser: builder.mutation<RegistrationResponse, CreateUser>({
      query: (newUser) => ({
        url: '/create',
        method: 'POST',
        body: newUser,
      }),
    }),
    requestPwdChange: builder.mutation<PwdChangeResponse, PwdChangeRequest>({
      query: (req) => ({
        url: '/changePasswordRequest',
        method: 'POST',
        body: req,
      }),
    }),
    changePassword: builder.mutation<PwdChangeSubmitResponse, PwdChangeSubmit>({
      query: (requestObj) => ({
        url: '/changePwd',
        method: 'POST',
        body: requestObj,
      }),
    }),
  }),
});

export const { useRequestPwdChangeMutation, useChangePasswordMutation, usePreregisterMutation, useCreateUserMutation } =
  registrationApi;
