import React from 'react';
import { Image, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const AnonHeader = () => {
  const { t } = useTranslation();

  return (
    <Navbar variant="dark" bg="primary">
      <Navbar.Brand>
        <Image alt={t('general.appShortName')} src="/logo245x50.png" />
      </Navbar.Brand>
    </Navbar>
  );
};
