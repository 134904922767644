import React from 'react';
import { Placeholder, Row, Col } from 'react-bootstrap';
import { StandardButton } from '../../common/ui/buttons/index.js';

export const SnapshotPlaceholderList = () => {
  return (
    <>
      <Placeholder>
        <Placeholder size="lg" xs="6" />
      </Placeholder>
      <Row>
        <Col xs="1">
          <StandardButton type="complete" disabled className="placeholder" />
        </Col>

        <Placeholder as={Col} xs="10">
          <Placeholder xs="10" />
        </Placeholder>
      </Row>
      <Row>
        <Col xs="1">
          <StandardButton type="complete" disabled className="placeholder" />
        </Col>
        <Placeholder as={Col} xs="10">
          <ul className="placeholder-wave">
            <li className="placeholder-wave">
              <span className="placeholder col-6" />
            </li>
            <li className="placeholder-wave">
              <span className="placeholder col-6" />
            </li>
          </ul>
        </Placeholder>
      </Row>
      <Row>
        <Col xs="1">
          <StandardButton type="complete" disabled className="placeholder" />
        </Col>

        <Placeholder as={Col} xs="10">
          <Placeholder xs="10" />
        </Placeholder>
      </Row>
    </>
  );
};
