import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from './helpers/customFetchBase.js';
import { AuthResponse, LoginAttributes } from '@soulhx/fs-common';
import { RootState } from '../stores/Store.js';

export const securityApi = createApi({
  reducerPath: 'security',
  baseQuery: customFetchBase,
  tagTypes: ['login'],
  endpoints: (builder) => ({
    refreshToken: builder.mutation<AuthResponse, void>({
      query: () => ({ url: '/auth/rT', method: 'POST' }),
    }),
  }),
});

export const { useRefreshTokenMutation } = securityApi;

export const loginApi = createApi({
  reducerPath: 'login',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/auth',
    credentials: 'include',
    prepareHeaders: (headers, api) => {
      const { getState } = api;
      const token = (getState() as RootState).persistedUI.token;

      if (token) {
        headers.set('Authorization', `bearer ${token}`);
      }

      return headers;
    },
    responseHandler: 'content-type',
  }),
  tagTypes: ['login'],
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, LoginAttributes>({
      query: (credentials) => ({
        url: '/li',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['login'],
    }),
  }),
});

export const { useLoginMutation } = loginApi;
