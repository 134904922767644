import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Alert, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useGetReadingPlanDataForDayQuery } from '../../../services/PlansService.js';
import { getDateForReadingPlan } from '../../../stores/UISlice.js';
import { StandardButton } from '../../common/ui/buttons/index.js';
import { ReadingPlanItem } from './ReadingPlanItem.js';
import { useToasts } from '../../../hooks/toast/Toasts.js';
import { CurrentRPPlaceholder } from '../helpers/read/CurrentRPPlaceholder.js';
import { useTranslation } from 'react-i18next';
import { CurrentReadingPlanHeader } from '../helpers/read/CurrentReadingPlanHeader.js';
import { ShxHelpText } from '../../common/ShxHelpText.js';
import { useUserSetting } from '../../../hooks/Setting.js';
import { ErrorLoadingDataMessage } from '../../common/ui/ErrorMessage.js';

interface ICurrentReadingPlan {
  showTitle?: boolean;
  showUnsub?: boolean;
}
export const CurrentReadingPlan = ({ showTitle = false, showUnsub = false }: ICurrentReadingPlan) => {
  const dateToShow = DateTime.fromISO(useSelector(getDateForReadingPlan));
  const { data, error, isLoading } = useGetReadingPlanDataForDayQuery(dateToShow.toFormat('yyyyMMdd'));
  const { settingValue: defaultVersion } = useUserSetting<string>('readDefaultVersion');
  const { showToast, ToastUI } = useToasts();
  const [showPlansAnyway, setShowPlansAnyway] = useState<boolean>(showUnsub);
  const { t } = useTranslation(['plan']);

  if (isLoading) return <CurrentRPPlaceholder />;
  if (error) return <ErrorLoadingDataMessage errors={[error]} />;

  if (data!.length < 1) {
    return (
      <Alert>
        <Alert.Heading>{t('plan:current.noPlans.heading')}</Alert.Heading>
        {t('plan:current.noPlans.text')}
      </Alert>
    );
  }

  if (data!.filter((item) => !item.dayCompleted).length < 1 && !showPlansAnyway) {
    return (
      <>
        <CurrentReadingPlanHeader data={data!} dateToShow={dateToShow} showTitle={showTitle} toastUI={ToastUI} />
        <ShxHelpText pageName="read" textName="completedReadingPlans" className="mb-0" />
        <Alert variant="success">
          {t('plan:current.plansComplete.msg')}
          <StandardButton
            type="showHide"
            done={false}
            variant="outline-success"
            onClick={() => setShowPlansAnyway(true)}
          />
        </Alert>
      </>
    );
  }

  return (
    <>
      <CurrentReadingPlanHeader data={data!} dateToShow={dateToShow} showTitle={showTitle} toastUI={ToastUI} />

      <ListGroup>
        {data!.map((item) => (
          <ReadingPlanItem
            key={item.ipId}
            plan={item}
            dateToShow={dateToShow.toISODate()!}
            version={defaultVersion}
            showToast={showToast}
            showUnsub={showUnsub}
          />
        ))}
      </ListGroup>
    </>
  );
};
