/**
 * Helper function used by **RTK** API services, to handle caching of
 * returned results.
 *
 * Takes an array of items (each item must have an `id` field), the
 * tag to be used for caching, and an optional param to specify
 * whether the `'LIST'` tag should also be used/updated.
 *
 * See https://redux-toolkit.js.org/rtk-query/usage/cache-behavior
 * for an explanation of how caching works in RTK. SoulHX uses the
 * `'LIST'` tag, by convention, for handling lists of items.
 *
 * @example
 * // typical usage
 * providesTags: (result) => providesList(result, 'TAGNAME');
 *
 * @example
 * // only single item should be updated, not list
 * providesTags: (result) => providesList(result, 'TAGNAME', false)
 *
 * @example
 * // if objects use a different field than `id`
 * providesTags: (result) => providesList(result.map((item)=>({ id:result.fieldName })), 'TAGNAME')
 *
 * @example
 * // Only a single item is returned, so it is converted to an array
 * // first -- but the code must ensure that a result is actually
 * // returned
 * providesTags: (result) => result ? providesList([result], 'TAGNAME') : []
 *
 * @param resultsWithIds Array of results (items must have `id` field)
 * @param tagType Tag to use for the cache
 * @param returnList Whether an overall `'LIST'` tag should also be included
 */
export function providesList<R extends { id?: string | number | undefined }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
  { returnList = true } = {}
) {
  if (returnList) {
    return resultsWithIds
      ? [
          { type: tagType, id: 'LIST' },
          ...resultsWithIds.filter((item) => item.id).map(({ id }) => ({ type: tagType, id })),
        ]
      : [{ type: tagType, id: 'LIST' }];
  }

  return resultsWithIds ? [...resultsWithIds.filter((item) => item.id).map(({ id }) => ({ type: tagType, id }))] : [];
}
