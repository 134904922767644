import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

export type SetMessageFunction = (message: string | JSX.Element) => string;
export type RemoveMessageFunction = (id: string) => void;

export interface ErrorsAndWarningsResponse {
  /**
   * React component to be included in parent for displaying messages
   */
  AlertUI: React.FC;

  /**
   * Add an error message to the UI
   */
  addErrorMessage: SetMessageFunction;

  /**
   * Add a warning message to the UI
   */
  addWarningMessage: SetMessageFunction;

  /**
   * Remove an error message from the UI (by ID)
   */
  removeErrorMessage: RemoveMessageFunction;

  /**
   * Remove a warning message from the UI (by ID)
   */
  removeWarningMessage: RemoveMessageFunction;
}

/**
 * Custom hook for working with error/warning messages. Provides UI for showing
 * dismissible errors and warnings, and functions for adding same.
 *
 * Up to calling component to:
 *
 * 1. Display the `AlertUI` component in a convenient place
 * 1. Distinguish between an 'error' and a 'warning'
 * 1. Keep track of IDs from added errors/warnings, to be able to remove them (if desired)
 *
 * @returns The Alert UI to be embedded, functions for adding error
 * and warning messages; and functions for removing error and warning
 * messages
 */
export const useErrorsAndWarnings = (): ErrorsAndWarningsResponse => {
  const [errors, setErrors] = useState<JSX.Element[]>([]);
  const [warnings, setWarnings] = useState<JSX.Element[]>([]);

  const addError: SetMessageFunction = (message) => {
    const uuid = uuidv4();

    const err: JSX.Element = <div key={uuid}>{message}</div>;
    const errorSlice = errors.slice();
    errorSlice.push(err);
    setErrors(errorSlice);

    return uuid;
  };

  const addWarning: SetMessageFunction = (message) => {
    const uuid = uuidv4();

    const w: JSX.Element = <div key={uuid}>{message}</div>;
    const warningSlice = warnings.slice();
    warningSlice.push(w);
    setWarnings(warningSlice);

    return uuid;
  };

  const removeError: RemoveMessageFunction = (id) => {
    const newErrors = errors.filter((item) => item.key !== id);
    setErrors(newErrors);
  };

  const removeWarning: RemoveMessageFunction = (id) => {
    const newWarnings = warnings.filter((item) => item.key !== id);
    setWarnings(newWarnings);
  };

  const AlertUI = () => {
    return (
      <div>
        <Alert variant="danger" dismissible show={errors.length > 0} onClose={() => setErrors([])}>
          {errors}
        </Alert>
        <Alert variant="warning" dismissible show={warnings.length > 0} onClose={() => setWarnings([])}>
          {warnings}
        </Alert>
      </div>
    );
  };

  return {
    AlertUI,
    addErrorMessage: addError,
    addWarningMessage: addWarning,
    removeErrorMessage: removeError,
    removeWarningMessage: removeWarning,
  };
};
