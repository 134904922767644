import React from 'react';
import { IIconProps } from '../../../../../dm/icons.js';

export const Logo = ({ color, size, className }: IIconProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      width={size}
      height={size}
      viewBox="0 0 1253 1253"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g transform="translate(0.000000,1253.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
        <path d="M1630 7328 l0 -3383 2117 -943 c1164 -518 2120 -941 2125 -940 5 2 -715 557 -1599 1233 l-1608 1230 -5 2860 -5 2859 -505 232 c-278 128 -508 233 -512 233 -5 1 -8 -1521 -8 -3381z" />
        <path d="M10380 10476 l-505 -232 -5 -2859 -5 -2860 -1608 -1230 c-884 -676 -1604 -1231 -1599 -1233 5 -1 961 422 2125 940 l2117 943 0 3383 c0 1860 -3 3382 -7 3381 -5 0 -235 -105 -513 -233z" />
        <path d="M6183 10051 c-17 -11 -44 -39 -61 -63 -25 -36 -41 -47 -98 -67 -38 -14 -81 -37 -96 -52 -28 -27 -28 -30 -28 -151 0 -68 5 -354 11 -636 7 -281 10 -514 8 -517 -3 -2 -300 -7 -659 -11 -547 -5 -660 -8 -683 -21 -33 -17 -66 -83 -67 -132 0 -28 -9 -42 -51 -80 -52 -46 -79 -92 -79 -132 0 -39 33 -91 80 -125 38 -28 49 -43 64 -91 25 -80 51 -119 88 -132 21 -8 238 -11 679 -11 l649 0 0 -37 c1 -139 20 -726 24 -730 2 -2 53 4 113 13 102 17 251 20 538 11 l60 -2 -4 373 -3 372 593 0 c327 0 611 4 632 9 56 13 81 42 108 123 20 59 31 76 66 102 105 77 106 196 2 262 -30 19 -39 32 -44 61 -10 63 -38 125 -68 147 -28 21 -36 21 -667 24 l-638 3 -6 92 c-3 51 -8 236 -11 412 -12 691 -15 767 -33 804 -24 48 -58 70 -122 78 -49 5 -60 11 -100 53 -25 27 -55 53 -67 59 -33 17 -95 13 -130 -8z" />
        <path d="M0 6152 l0 -3418 2638 -459 c1450 -252 2646 -460 2657 -463 14 -3 17 -1 10 6 -5 6 -961 403 -2122 883 l-2113 872 -2 2876 -3 2876 -505 116 c-278 65 -517 120 -532 123 l-28 6 0 -3418z" />
        <path d="M11990 9447 l-525 -122 -3 -2876 -2 -2876 -2113 -872 c-1161 -480 -2116 -877 -2122 -883 -7 -7 -4 -9 10 -6 11 3 1207 211 2658 463 l2637 459 0 3418 c0 1880 -3 3418 -7 3417 -5 0 -244 -55 -533 -122z" />
        <path d="M6125 6846 c-112 -14 -142 -26 -156 -63 -6 -17 -3 -274 10 -777 11 -413 23 -903 27 -1089 5 -240 11 -342 19 -354 12 -16 23 -15 169 2 153 18 306 17 467 -1 51 -6 58 -4 68 14 8 15 6 220 -9 764 -11 409 -24 907 -29 1107 -7 280 -12 367 -23 376 -33 33 -348 44 -543 21z" />
        <path d="M6215 4399 c-118 -14 -154 -26 -160 -52 -4 -15 26 -98 88 -247 l95 -225 63 -6 c35 -4 100 -7 144 -8 l80 -1 94 239 c108 277 108 272 9 290 -88 16 -318 22 -413 10z" />
        <path d="M6265 3782 c15 -48 111 -262 118 -264 7 -2 127 262 127 278 0 2 -57 4 -126 4 -117 0 -125 -1 -119 -18z" />
      </g>
    </svg>
  );
};
