import React, { Suspense, lazy, useState } from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import { useLogoutMutation, useUpdateUserBaseMutation, useUserDetailsQuery } from '../../services/UserService.js';
import { SidebarCollapseWidget } from './ui/SidebarCollapseWidget.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearAllCachedData } from '../../stores/Store.js';
import packageJson from '../../../package.json';
import { TemplateEditor } from './templates/TemplateEditor.js';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { UserAttributes, UserSetting } from '@soulhx/fs-common';
import { StandardButton } from './ui/buttons/index.js';
import { handleInit } from '../../hooks/HandleInit.js';
import { ShxHelpText } from './ShxHelpText.js';
import HomeSettings from '../home/HomeSettings.js';
import { useUserSetting } from '../../hooks/Setting.js';
import { useBulkUserSettings } from '../../hooks/BulkSettings.js';
import { useDispatch, useSelector } from 'react-redux';
import { getShowHoverText, setShowHoverText } from '../../stores/PersistedUI.js';
import { getShowAllHelpText, updateShowAllHelpText } from '../../stores/UISlice.js';
const PraySettings = lazy(() => import('../pray/PraySettings.js'));
const DoSettings = lazy(() => import('../do/DoSettings.js'));
const ReadSettings = lazy(() => import('../read/ReadSettings.js'));
const PlanSettings = lazy(() => import('../plans/PlanSettings.js'));

interface ISettings {
  closeFunc(): void;
}
export const Settings = ({ closeFunc }: ISettings) => {
  const location = useLocation();
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const { settings, settingsError, settingsLoading, bulkUpdate } = useBulkUserSettings();
  const { settingValue: showPrayerSettings, update: updateShowPrayerSettings } =
    useUserSetting<boolean>('prayerShowSettings');
  const { settingValue: showReadSettings, update: updateShowReadSettings } =
    useUserSetting<boolean>('readShowSettings');
  const { settingValue: showSizeIndicator, update: updateShowSizeIndicator } =
    useUserSetting<boolean>('adminShowSizeIndicator');
  const { settingValue: showActionBtns, update: updateShowActionBtns } =
    useUserSetting<boolean>('generalShowActionBtns');
  const { settingValue: showDo, update: updateShowDo } = useUserSetting<boolean>('doShowSettings');
  const { settingValue: showPlan, update: updateShowPlan } = useUserSetting<boolean>('planShowSettings');
  const { settingValue: showTemplates, update: updateShowTemplates } =
    useUserSetting<boolean>('generalShowTemplateSetting');
  const { settingValue: showHelpTextReset, update: updateShowHelpTextReset } = useUserSetting<boolean>(
    'generalShowHelpTextResetSetting'
  );
  const { settingValue: showHomeSettings, update: updateShowHomeSettings } =
    useUserSetting<boolean>('homeShowSettings');
  const { data: userData, error: userError, isLoading: userLoading } = useUserDetailsQuery();
  const [showTemplateEditor, setShowTemplateEditor] = useState<boolean>(false);
  const { t } = useTranslation();
  const [displayNameForField, setDisplayNameForField] = useState(userData?.displayName || '');
  const [displayNameUpdated, setDisplayNameUpdated] = useState<boolean>(false);
  const [updateUserApi] = useUpdateUserBaseMutation();
  const { settingValue: showResetSettingsBtn, update: updateShowResetSettings } =
    useUserSetting<boolean>('generalShowResetSettings');
  const showTooltips = useSelector(getShowHoverText);
  const showAllHelpText = useSelector(getShowAllHelpText);
  const dispatch = useDispatch();

  const resp = handleInit([userLoading, settingsLoading], [userError, settingsError]);
  if (resp) return resp;

  return (
    <>
      <ShxHelpText pageName="settings" textName="welcomeMsg" firstLevel variant="success" />

      <h5>{t('settings.title')}</h5>

      <Form.Group className="mb-5">
        <ShxHelpText pageName="settings" textName="displayName" className="mb-0" />
        <Form.Label>{t('settings.fields.displayName.label')}</Form.Label>
        <Form.Control
          type="text"
          value={displayNameForField}
          isValid={displayNameUpdated}
          placeholder={t('settings.fields.displayName.placeholder')}
          onChange={(e) => {
            setDisplayNameForField(e.currentTarget.value);
            setDisplayNameUpdated(false);
          }}
          onBlur={(e) => {
            if (
              e.currentTarget.value === userData!.displayName ||
              (e.currentTarget.value.length < 1 && userData!.displayName === undefined)
            )
              return;

            const newUserObj: UserAttributes = {
              ...userData!,
              displayName: e.currentTarget.value.length > 0 ? e.currentTarget.value : undefined,
            };

            updateUserApi(newUserObj).then(() => {
              setDisplayNameUpdated(true);
            });
          }}
        />
        {displayNameUpdated && (
          <Form.Control.Feedback type="valid">{t('settings.fields.displayName.success')}</Form.Control.Feedback>
        )}
      </Form.Group>

      {userData!.isAdmin && (
        <>
          <ShxHelpText pageName="settings" textName="showSizeIndicator" className="mt-5 mb-0" />
          <Form.Group className="mb-5">
            <Form.Check
              type="switch"
              checked={showSizeIndicator}
              label={t('settings.showSizeIndicator')}
              onChange={() => updateShowSizeIndicator(!showSizeIndicator)}
            />
          </Form.Group>

          <ShxHelpText pageName="settings" textName="showAllHelpText" className="mt-5 mb-0" />
          <Form.Group className="mb-5">
            <Form.Check
              type="switch"
              checked={showAllHelpText}
              label={t('settings.showAllHelpText')}
              onChange={() => dispatch(updateShowAllHelpText(!showAllHelpText))}
            />
          </Form.Group>
        </>
      )}

      <ShxHelpText pageName="settings" textName="showTooltips" className="mt-5 mb-0" variant="warning" />
      <Form.Group className="mb-5">
        <Form.Check
          type="switch"
          checked={showTooltips}
          label={t('settings.showTooltips')}
          onChange={() => dispatch(setShowHoverText(!showTooltips))}
        />
      </Form.Group>

      <ShxHelpText pageName="settings" textName="showActionBtns" className="mt-5 mb-0" />
      <Form.Group className="mb-5">
        <Form.Check
          type="switch"
          checked={showActionBtns}
          label={t('settings.showActionBtns')}
          onChange={() => updateShowActionBtns(!showActionBtns)}
        />
      </Form.Group>

      <ShxHelpText pageName="settings" textName="collapseWidgets" className="mt-5 mb-1" collapsed />

      <SidebarCollapseWidget
        title={t('settings.collapseSections.templates')}
        visible={showTemplates}
        clickFunction={() => updateShowTemplates(showTemplates ? false : true)}
      >
        <ShxHelpText pageName="settings" textName="templatesHelpText" className="my-0" />
        <StandardButton type="edit" onClick={() => setShowTemplateEditor(true)}>
          {t('settings.templates.launchButton')}
        </StandardButton>
        <TemplateEditor show={showTemplateEditor} onHide={() => setShowTemplateEditor(false)} />
      </SidebarCollapseWidget>

      {location.pathname === '/' && (
        <SidebarCollapseWidget
          title={t('settings.collapseSections.home')}
          visible={showHomeSettings}
          clickFunction={() => updateShowHomeSettings(showHomeSettings ? false : true)}
        >
          <Suspense fallback={'...'}>
            <HomeSettings />
          </Suspense>
        </SidebarCollapseWidget>
      )}

      {location.pathname.startsWith('/pray') && (
        <SidebarCollapseWidget
          title={t('settings.collapseSections.prayer')}
          visible={showPrayerSettings}
          clickFunction={() => updateShowPrayerSettings(showPrayerSettings ? false : true)}
        >
          <Suspense fallback={'...'}>
            <PraySettings />
          </Suspense>
        </SidebarCollapseWidget>
      )}

      {location.pathname.startsWith('/read') && (
        <SidebarCollapseWidget
          title={t('settings.collapseSections.read')}
          visible={showReadSettings}
          clickFunction={() => updateShowReadSettings(showReadSettings ? false : true)}
        >
          <Suspense fallback={'...'}>
            <ReadSettings />
          </Suspense>
        </SidebarCollapseWidget>
      )}

      {location.pathname.startsWith('/do') && (
        <SidebarCollapseWidget
          title={t('settings.collapseSections.do')}
          visible={showDo}
          clickFunction={() => updateShowDo(showDo ? false : true)}
        >
          <Suspense fallback={'...'}>
            <DoSettings closeFn={closeFunc} />
          </Suspense>
        </SidebarCollapseWidget>
      )}

      {location.pathname.startsWith('/plan') && (
        <SidebarCollapseWidget
          title={t('settings.collapseSections.plans')}
          visible={showPlan}
          clickFunction={() => updateShowPlan(showPlan ? false : true)}
        >
          <Suspense fallback={'...'}>
            <PlanSettings />
          </Suspense>
        </SidebarCollapseWidget>
      )}

      <SidebarCollapseWidget
        title={t('settings.showHelpReset')}
        visible={showHelpTextReset}
        clickFunction={() => updateShowHelpTextReset(showHelpTextReset ? false : true)}
      >
        <div>
          <Button
            variant="secondary"
            onClick={() => {
              const itemsToFlip: UserSetting[] = settings
                .filter((s) => s.settingId.startsWith('helpText') && s.settingBoolValue === false)
                .map((s) => ({ settingId: s.settingId, settingBoolValue: true }));
              bulkUpdate(itemsToFlip);
            }}
          >
            {t('settings.resetHelpTextBtn')}
          </Button>
        </div>
      </SidebarCollapseWidget>

      <SidebarCollapseWidget
        title={t('settings.fields.showSettingsReset.title')}
        visible={showResetSettingsBtn}
        clickFunction={() => updateShowResetSettings(showResetSettingsBtn ? false : true)}
      >
        <p>
          <Form.Text>{t('settings.fields.showSettingsReset.description')}</Form.Text>
        </p>

        <ShxHelpText pageName="settings" textName="resetSettings" />

        <Button
          variant="secondary"
          onClick={async () => {
            const settingsCache = await caches.open('settings');
            const keys = await settingsCache.keys();
            for (const key of keys) {
              await settingsCache.delete(key.url);
            }
            window.location.reload();
          }}
        >
          {t('settings.fields.showSettingsReset.btn')}
        </Button>
      </SidebarCollapseWidget>

      <Stack gap={3} className="my-4">
        <ShxHelpText pageName="settings" textName="tutorialBtn" className="mb-0" />
        <NavLink to="/tutorial" className="btn btn-primary" onClick={() => closeFunc()}>
          {t('settings.tutorial')}
        </NavLink>

        <ShxHelpText pageName="settings" textName="mdTutorialBtn" className="mb-0" />
        <NavLink to="/mdtutorial" className="btn btn-primary" onClick={() => closeFunc()}>
          {t('settings.mdTutorial')}
        </NavLink>

        <ShxHelpText pageName="settings" textName="discussionGroupBtn" className="mb-0" />
        <NavLink
          to="https://github.com/sernaferna/soulhx-feedback/discussions"
          className="btn btn-secondary"
          onClick={closeFunc}
          target="_blank"
        >
          {t('settings.discussionGroupBtn')}
        </NavLink>

        <NavLink to="/changePwd" className="btn btn-warning" onClick={() => closeFunc()}>
          {t('settings.changePwd')}
        </NavLink>

        <Button
          className="mb-3"
          variant="danger"
          onClick={async () => {
            await logout().unwrap();
            clearAllCachedData();
            window.localStorage.setItem('logout', Date.now().toLocaleString());
            navigate('/');
          }}
        >
          {t('settings.logoutBtn')}
        </Button>
      </Stack>

      <div>
        <Form.Text muted>{t('settings.version', { version: packageJson.version })}</Form.Text>
      </div>
    </>
  );
};

export default Settings;
