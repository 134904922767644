import React from 'react';
import { MarkdownSectionPart } from '../../../../dm/tutorial.js';
import { useTranslation } from 'react-i18next';
import { useUserSetting } from '../../../../hooks/Setting.js';
import { ExampleContainer } from '../ExampleContainer.js';
import { MDPreview, MarkdownBox } from '../../md/index.js';

/**
 * Helper function to calculate how big (i.e. how many lines) a
 * textbox should be for showing the "raw" markdown, for examples.
 * It isn't going to be perfect, but basically:
 *
 * * Calculates number of lines of text (based on newlines)
 * * For long lines, adds 1 "newline" for every block of 80 characters
 *
 * Non-fixed-with fonts and various other things will make this less
 * than perfect, but it'll do a *fairly* good job.
 *
 * @param md The markdown being displayed
 * @returns Number of lines to show in the textbox, minimum 1, maximum 20
 */
const calculateHeight = (md: string): number => {
  const lines = md.split('\n');
  let numNewLines = lines.length;
  lines.forEach((line) => {
    const adder = Math.floor(line.length / 80);
    numNewLines += adder;
  });

  return Math.min(Math.max(numNewLines, 1), 20);
};

interface IMDPart {
  i18nNS: string;
  i18nLocation: string;
  part: MarkdownSectionPart;
}
export const MDPart = ({ part, i18nNS, i18nLocation }: IMDPart) => {
  const { t } = useTranslation(['translation', i18nNS]);
  const { settingValue: defaultVersion } = useUserSetting<string>('readDefaultVersion');

  const passageContextProp = t(`${i18nNS}:passageContext`);
  const passageContext = passageContextProp === `${i18nNS}:passageContext` ? undefined : passageContextProp;

  const content = t(`${i18nNS}:${i18nLocation}.${part.i18nLocation}`);

  if (part.partType === 'text') {
    return (
      <MDPreview content={content} className="mb-3" defaultVersion={defaultVersion} passageContext={passageContext} />
    );
  }

  if (part.partType === 'heading') {
    return <MDPreview content={`**${content}**`} className="mt-3 mb-0" />;
  }

  return (
    <ExampleContainer>
      <div className="mb-3">
        <MarkdownBox
          content={content}
          changeCallback={() => {}}
          height={calculateHeight(content)}
          defaultVersion={defaultVersion}
          passageContext={passageContext}
          hideAllControls
          readOnly
        />
      </div>

      <MDPreview content={t('translation:tutorials.renderAs')} className="mb-3" />

      <MDPreview
        content={content}
        className="mb-3"
        defaultVersion={defaultVersion}
        passageContext={passageContext}
        shaded
      />
    </ExampleContainer>
  );
};
