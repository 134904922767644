import React, { useState } from 'react';
import { SetMessageFunction } from '../../hooks/ErrorsAndWarnings.js';
import { DateTime } from 'luxon';
import { useSelector, useDispatch } from 'react-redux';
import { getDateShowingInActions, updateDateShowingInActions } from '../../stores/UISlice.js';
import { useUserDetailsQuery } from '../../services/UserService.js';
import { SHXButton, StandardButton } from '../common/ui/buttons/index.js';
import { Card, Modal } from 'react-bootstrap';
import { ActionWidgetForm } from './ActionWidgetForm.js';
import { CalendarView } from './CalendarView.js';
import { useTranslation } from 'react-i18next';
import { handleInit } from '../../hooks/HandleInit.js';

interface IActionsWidget {
  showTitle?: boolean;
  setErrorMessage: SetMessageFunction;
  showDetails?: boolean;
  showLongNames?: boolean;
}
export const ActionsWidget = ({
  showTitle = false,
  setErrorMessage,
  showDetails = true,
  showLongNames = true,
}: IActionsWidget) => {
  const dateToShow = DateTime.fromISO(useSelector(getDateShowingInActions));
  const dispatch = useDispatch();
  const { data: userData, error: userError, isLoading: userLoading } = useUserDetailsQuery();
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const { t } = useTranslation(['do']);

  const resp = handleInit([userLoading], [userError]);
  if (resp) return resp;

  const signupDate = DateTime.fromISO(userData!.signupDate);
  const showPrevButton = dateToShow.diff(signupDate, 'days').days > 0;
  const showNextButton = dateToShow.diff(DateTime.now(), 'days').days < -1;

  return (
    <Card className="m-0 border-0">
      <Card.Body className="p-1">
        {showTitle && <h5 className="text-center">{t('do:actionsWidget.title')}</h5>}
        {showDetails && (
          <h5 className="d-flex justify-content-center">
            <StandardButton
              type="left"
              onClick={() => {
                const newDate = dateToShow.minus({ day: 1 });
                dispatch(updateDateShowingInActions(newDate.toISO()!));
              }}
              disabled={!showPrevButton}
              className="p-0"
            />

            <SHXButton variant="outline-dark" className="user-selecct-none p-0" onClick={() => setShowDatePicker(true)}>
              {dateToShow.toLocaleString()}
            </SHXButton>

            <StandardButton
              type="right"
              onClick={() => {
                const newDate = dateToShow.plus({ day: 1 });
                dispatch(updateDateShowingInActions(newDate.toISO()!));
              }}
              disabled={!showNextButton}
              className="p-0"
            />
          </h5>
        )}
        <ActionWidgetForm
          date={showDetails ? dateToShow : DateTime.now()}
          setErrorMessage={setErrorMessage}
          showDetails={showDetails}
          showLongNames={showLongNames}
        />
      </Card.Body>
      <Modal
        show={showDatePicker}
        onHide={() => {
          setShowDatePicker(false);
        }}
      >
        <Modal.Header closeButton>{t('do:actionsWidget.datePickerTitle')}</Modal.Header>
        <Modal.Body>
          <CalendarView close={() => setShowDatePicker(false)} />
        </Modal.Body>
      </Modal>
    </Card>
  );
};
