import { DateTime } from 'luxon';

/**
 * Status of a prayer item per its target date
 */
export enum TargetDateStatuses {
  /**
   * due today, or overdue
   */
  red = 'red',

  /**
   * due soon
   */
  yellow = 'yellow',

  /**
   * Due in the future, or no due date attached
   */
  normal = 'normal',
}

/**
 * Helper function to calculate the "status" of a prayer item (red,
 * yellow, or normal), based on its target date (if any).
 *
 * @param targetDate The date to use for comparison
 * @returns Status (for use in visualising it)
 */
export const calculateTargetDateStatus = (targetDate: string | undefined): TargetDateStatuses => {
  if (!targetDate) return TargetDateStatuses.normal;

  const td = DateTime.fromISO(targetDate!).startOf('day');
  const daysInFuture = td.diff(DateTime.now().startOf('day'), 'days').days;

  if (daysInFuture < 3 && daysInFuture >= 1) return TargetDateStatuses.yellow;
  if (daysInFuture < 1) return TargetDateStatuses.red;

  return TargetDateStatuses.normal;
};
