import { useState, useEffect } from 'react';

/**
 * Enum of window sizes, per Bootstrap conventions
 */
export enum BSWindowSize {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1400,
}

const getBSWidthForNum = (width: number): BSWindowSize => {
  if (width < BSWindowSize.SM) {
    return BSWindowSize.XS;
  }

  if (width < BSWindowSize.MD) {
    return BSWindowSize.SM;
  }

  if (width < BSWindowSize.LG) {
    return BSWindowSize.MD;
  }

  if (width < BSWindowSize.XL) {
    return BSWindowSize.LG;
  }

  if (width < BSWindowSize.XXL) {
    return BSWindowSize.XL;
  }

  return BSWindowSize.XXL;
};

/**
 * Details about the screen size
 */
export interface WindowSize {
  /**
   * Width of the viewable area in pixels
   */
  width: number;

  /**
   * Height of the viewable area in pixels
   */
  height: number;

  /**
   * Bootstrap breakpoint size, per `BSWindowSize`
   */
  bswidth: BSWindowSize;
}

/**
 * Hook for working with the screen size. Should rarely be
 * used---typically want to use Bootstrap classes wherever possible
 * (e.g. `m-0 m-sm-1` type of thing)---but necessary in some cases
 * where Bootstraps classes can't do the job, such as setting
 * different `colspan` for different screen sizes.
 *
 * @returns A `WindowSize` object which changes whenever the size of the screen changes
 */
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 1,
    height: 1,
    bswidth: BSWindowSize.XS,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        bswidth: getBSWidthForNum(window.innerWidth),
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
