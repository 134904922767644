import { Verse } from './Verse.js';

/**
 * Standard codes used to represent the **status** of a Plan in the
 * system.
 */
export enum PlanStatus {
  /**
   * Mostly not used by external code, but used internally by the API
   */
  Unsaved = 'unsaved',

  /**
   * Plan has been saved, but isn't yet published
   */
  Saved = 'saved',

  /**
   * Plan is published, and able to be used
   */
  Published = 'published',

  /**
   * Plan has been removed and can no longer be subscribed to
   * (though existing subscriptions can continue)
   */
  Deleted = 'deleted',
}

/**
 * Base fields for adding a Day to a Plan
 */
export interface BasePlanDay {
  osis?: string;
  sequenceNum: number;
  verses?: Verse[];
}

/**
 * Extends {@link BasePlanDay} with attributes created by the API
 */
export interface PlanDay extends BasePlanDay {
  id: string;
}

/**
 * Base fields for creating a new Plan
 */
export interface BasePlanAttributes {
  name: string;
  description: string;
  length: number;
  isAdmin: boolean;
  includesApocrypha: boolean;
  includeWeekends: boolean;
  version: string;
  isFreeform: boolean;
  osis?: string;
  days?: BasePlanDay[] | PlanDay[];
}

/**
 * Extends {@link BasePlanAttributes} with fields created by the API
 */
export interface PlanAttributes extends BasePlanAttributes {
  planId: string;
  planInstanceId: string;
  status: PlanStatus;
}

/**
 * Base fields for creating an Instantiated Plan
 */
export interface BaseInstantiatedPlan {
  planInstanceId: string;
  percentageComplete?: number;
}

/**
 * Base fields for creating a Day for an Instantiated Plan
 */
export interface BaseInstantiatedPlanDay {
  completed: boolean;
  scheduledDate: string;
}

/**
 * Extends {@link BaseInstantiatedPlanDay} with fields created by the API
 */
export interface InstantiatedPlanDay extends BaseInstantiatedPlanDay {
  id: string;
}

/**
 * Extends {@link BaseInstantiatedPlan} with fields created by the API
 */
export interface InstantiatedPlan extends BaseInstantiatedPlan {
  id: string;
  days?: InstantiatedPlanDay[];
}

/**
 * Status to use for displaying a reading plan item
 */
export enum ReadingPlanStatus {
  /**
   * A normal day
   */
  normal = 'normal',

  /**
   * *Some* days before the current date haven't been completed
   */
  yellow = 'yellow',

  /**
   * *Numerous* days before the current date haven't been completed
   */
  red = 'red',
}

/**
 * Encapsulates a day for a reading plan
 */
export interface ReadingPlanDay {
  ipId: string;
  ipDayId: string;
  planName: string;
  osisForDay?: string;
  dayCompleted: boolean;
  date: string;
  readingPlanStatus: ReadingPlanStatus;
  sequenceNum: number;
  verses?: Verse[];
  totalDays: number;
}

/**
 * Data required for "catching up" a reading plan
 */
export interface CatchUpIPParams {
  fromDate: string;
  markPrevAsRead: boolean;
}

/**
 * Subset of fields for doing lightweight updates of an existing plan
 */
export interface UpdatePlanName {
  name: string;
  description: string;
}

/**
 * Fields used for getting all available plans from the API
 */
export interface GetAllPlansSearch {
  page?: number;
}
