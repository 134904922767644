import { getAllRefsFromString } from '@soulhx/fs-common';
import { RendererExtension, TokenizerExtension, marked } from 'marked';

const fullMatchRE = /^> (?:> )*(?:\(\((.*)\)\) )/;
const lineMatchRE = /^> /;

export const BlockQuoteExtension = (
  context: string | undefined = undefined
): TokenizerExtension | RendererExtension => {
  return {
    name: 'BlockquoteExtension',
    level: 'block',
    start(src) {
      return src.match(/^> /)?.index || -1;
    },
    tokenizer(src) {
      const rule = /^(> ?([^\n]*)(?:\n|$))+/;
      const match = rule.exec(src);
      if (!match) {
        return;
      }

      const rawParagraphs = match[0].trim().split('\n');

      let citation: string | undefined = undefined;
      const responseStrings: string[] = [];

      for (let i = 0; i < rawParagraphs.length; i++) {
        let fixedString = rawParagraphs[i];

        if (i === 0) {
          const citationMatch = fullMatchRE.exec(fixedString);
          if (citationMatch) {
            citation = citationMatch[1];
            fixedString = fixedString.replace(`((${citationMatch[1]})) `, '');
          }
        }

        let level = 0;

        while (lineMatchRE.test(fixedString)) {
          level++;
          fixedString = fixedString.replace(lineMatchRE, '');
        }

        if (fixedString.trim() === '') {
          fixedString = '&nbsp;';
        }

        responseStrings.push(
          `<p style="margin-top: 0; margin-bottom: 0; padding-left: ${level}em;">${fixedString}</p>`
        );
      }
      const responseString = responseStrings.join('');

      const token = {
        type: 'BlockquoteExtension',
        raw: match[0],
        citation: citation,
        bodyText: responseString,
        tokens: [],
      };
      this.lexer.inline(token.bodyText, token.tokens);
      return token;
    },
    renderer(token) {
      const responseStrings: string[] = [];
      responseStrings.push('<blockquote>');
      responseStrings.push(this.parser.parseInline(token.tokens || token.bodyText));

      if (token.citation) {
        let mdCitation = token.citation as string;
        if (!/^<a/.test(token.citation) && !/\[\|/.test(token.citation)) {
          const refs = getAllRefsFromString(mdCitation, { context });

          let indexFactor = 0;
          for (let i = 0; i < refs.length; i++) {
            mdCitation =
              mdCitation.substring(0, refs[i].indices[0] + indexFactor) +
              `[|${refs[i].osis}|]` +
              mdCitation.substring(refs[i].indices[1] + 1 + indexFactor);
            indexFactor = mdCitation.length - token.citation.length;
          }
        }

        const parsedCitation = marked.parseInline(mdCitation);
        if (typeof parsedCitation === 'string') mdCitation = parsedCitation;

        responseStrings.push(
          '<p style="margin-top: 0; margin-bottom: 0; text-align: right; font-style: italic;">',
          mdCitation,
          '</p>'
        );
      }

      responseStrings.push('</blockquote>');
      const responseString = responseStrings.join('');

      return responseString;
    },
  };
};
