import { CustomError, ErrorResponse, StandardErrorCodes } from './CustomError.js';

/**
 * Generic error thrown by any application (but really just the
 * API applications) if an error is encountered that isn't
 * specifically covered by another exception.
 */
export class SystemError extends CustomError {
  statusCode = 500;
  errorCode = StandardErrorCodes.SystemError;

  constructor(public msg: string) {
    super(`System error: ${msg}`);
    Object.setPrototypeOf(this, SystemError.prototype);
  }

  serializeErrors(): ErrorResponse {
    return { errorCode: this.errorCode, errors: [{ message: `System error: ${this.msg}` }] };
  }

  serializeInternalError(): string {
    return `System error: ${this.msg}`;
  }
}
