import React from 'react';
import { useHtmlFromMD } from '../../../hooks/md/index.js';

export interface IMDPreview {
  /**
   * The markdown being rendered
   */
  content: string;

  /**
   * The default version of the Bible to use in rendering the content
   */
  defaultVersion?: string;

  /**
   * The "context" of any Scripture to be rendered
   */
  passageContext?: string;

  /**
   * Indicates whether "unnecessary" verses should be included in
   * rendered content for Bible verses; e.g. for the passage
   * Romans 1:1-32 (which is the entire chapter), if this param is
   * set to `false` the output will simply be "Romans 1" whereas
   * if it is `true` the output will be the full "Romans 1:1-32".
   *
   * Defaults to `false`
   */
  includeVerses?: boolean;

  /**
   * Whether the component should be rendered in a "shaded" version
   * (i.e. with a different background colour)
   *
   * Defaults to `false`
   */
  shaded?: boolean;

  /**
   * Tries to use the most basic version of MD possible
   *
   * Defaults to `false`
   */
  minimal?: boolean;

  /**
   * Optional additional classes to pass
   */
  className?: string;
}

export const MDPreview = ({
  content,
  defaultVersion,
  passageContext,
  className,
  shaded = false,
  minimal = false,
  includeVerses = false,
}: IMDPreview) => {
  const renderedOutput = useHtmlFromMD(content, {
    defaultVersion: defaultVersion,
    scriptureContext: passageContext,
    minimal: minimal,
    includeVerses: includeVerses,
  });

  return (
    <div
      className={`${shaded ? 'shx-md-viewer-shaded' : 'shx-md-viewer'} ${className || ''}`}
      dangerouslySetInnerHTML={{ __html: renderedOutput }}
    />
  );
};
