import React, { ReactNode, useEffect } from 'react';
import { CloseButton, Toast } from 'react-bootstrap';
import { ToastIcon } from './ToastIcon.js';
import { ToastType } from './types.js';

export interface ISHXToast {
  id?: string;
  destroy: () => void;
  title?: string;
  content: ReactNode;
  duration?: number;
  type?: ToastType;
}
export const SHXToast = ({ destroy, title, content, duration = 0, type = ToastType.None }: ISHXToast) => {
  useEffect(() => {
    if (!duration) return;

    const timer = setTimeout(() => {
      destroy();
    }, duration);

    return () => clearTimeout(timer);
  }, [destroy, duration]);

  return (
    <Toast autohide delay={duration} onClose={destroy} show={true} bg={`${type}-subtle`}>
      {title && (
        <Toast.Header className="p-0 p-sm-2">
          <ToastIcon className="me-1" type={type} />
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
      )}
      <Toast.Body className="position-relative p-0 p-sm-2">
        {content}
        {!title && <CloseButton className="position-absolute top-0 end-0" onClick={destroy} />}
      </Toast.Body>
    </Toast>
  );
};
