import React from 'react';
import { Badge } from 'react-bootstrap';
import { calendarSymbols, themeColours } from './dm.js';
import { v4 as uuid } from 'uuid';

interface ILegendItems {
  options: string[];
}
export const LegendItems = ({ options }: ILegendItems) => {
  return (
    <div>
      {options.map((o, index) => (
        <Badge key={uuid()} pill bg={themeColours[index]}>
          {calendarSymbols[index]} {o}
        </Badge>
      ))}
    </div>
  );
};
