import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { ToastType, TOAST_FADE_TIME } from './helpers/types.js';
import { ToastContainer } from 'react-bootstrap';
import { SHXToast, ISHXToast } from './helpers/SHXToast.js';

export interface IShowToastParams {
  /**
   * (Optional) title for the toast; if not passed, the Toast won't
   * have a header (including icon), but will still show in the proper
   * colour.
   */
  title?: string;

  /**
   * Body of the toast
   */
  content: ReactNode;

  /**
   * Type; drives colour as well as icon (if title passed)
   */
  type: ToastType;

  /**
   * How long (in milliseconds) the toast should be shown
   */
  duration?: number;
}

export type ShowToastFunction = (params: IShowToastParams) => void;

export interface UseToastResponse {
  showToast: ShowToastFunction;
  ToastUI: JSX.Element;
}

export const useToasts = (): UseToastResponse => {
  const [toasts, setToasts] = useState<ISHXToast[]>([]);

  const removeToast = useCallback(
    (id: string) => {
      return () => {
        setToasts(toasts.filter((item) => item.id !== id));
      };
    },
    [setToasts, toasts]
  );

  const showToast = useCallback(
    ({ title, content, type, duration = TOAST_FADE_TIME }: IShowToastParams) => {
      const newList = toasts.slice();
      const id = Math.random().toString(32).substring(2, 9);
      newList.push({
        content: content,
        title: title,
        type: type,
        duration: duration,
        id: id,
        destroy: removeToast(id),
      });
      setToasts(newList);
    },
    [setToasts, toasts, removeToast]
  );

  const toastList = useMemo(() => {
    return toasts.map((item) => (
      <SHXToast
        title={item.title}
        content={item.content}
        destroy={removeToast(item.id!)}
        duration={item.duration}
        type={item.type}
        key={item.id}
      />
    ));
  }, [toasts, removeToast]);

  const parentDiv = (
    <div aria-live="polite" aria-atomic="true" className="position-relative">
      <ToastContainer position="top-end" className="position-fixed top-0 end-0" id="main-toast-container">
        {toastList}
      </ToastContainer>
    </div>
  );

  return { showToast, ToastUI: parentDiv };
};

export { ToastType, TOAST_FADE_TIME };
