import React, { useState } from 'react';
import { TutorialData } from '../../../../dm/tutorial.js';
import { useTranslation } from 'react-i18next';
import { useBulkUserSettings } from '../../../../hooks/BulkSettings.js';
import { StandardButton } from '../../ui/buttons/index.js';
import { Form } from 'react-bootstrap';
import { MDPreview } from '../../md/index.js';

interface PartialSection {
  complete: boolean;
  i18nSection: string;
  settingName: string;
}

interface IUncompleteSection {
  tuturialData: TutorialData;
}
export const UnCompleteSectionWidget = ({ tuturialData }: IUncompleteSection) => {
  const { t } = useTranslation(['translation', tuturialData.i18nNS]);
  const { bulkUpdate } = useBulkUserSettings();
  const [internalSections, setInternalSections] = useState<PartialSection[]>(
    tuturialData.sections.map((section) => ({
      i18nSection: section.i18nSection,
      settingName: section.settingName,
      complete: section.complete || true,
    }))
  );
  if (tuturialData.sections.every((section) => section.complete === undefined)) {
    return <></>;
  }

  return (
    <div>
      <MDPreview content={t('translation:tutorials.success.resetMsg')} className="mb-3" />

      {internalSections.length > 1 && (
        <Form.Check
          type="checkbox"
          checked={internalSections.every((section) => section.complete === false)}
          label={t('translation:tutorials.success.resetAll')}
          onChange={(e) => {
            if (!e.currentTarget.checked) {
              const newSections = internalSections.map((section) => ({ ...section, complete: true }));
              setInternalSections(newSections);
            } else {
              const newSections = internalSections.map((section) => ({ ...section, complete: false }));
              setInternalSections(newSections);
            }
          }}
        />
      )}

      {internalSections.map((section) => (
        <Form.Check
          key={`uncompleteradio=${section.settingName}`}
          type="checkbox"
          checked={!section.complete}
          label={t('translation:tutorials.success.unCompleteSection', {
            sectionName: t(`${tuturialData.i18nNS}:${section.i18nSection}.tabName`),
          })}
          onChange={() => {
            const newSections = internalSections.slice();
            const index = newSections.findIndex((s) => s.i18nSection === section.i18nSection);
            newSections[index].complete = !newSections[index].complete;

            setInternalSections(newSections);
          }}
        />
      ))}

      {!internalSections.every((s) => s.complete) && (
        <StandardButton
          variant="outline-success"
          type="save"
          onClick={() => {
            bulkUpdate(internalSections.map((s) => ({ settingId: s.settingName, settingBoolValue: s.complete })));
          }}
        >
          {t('translation:tutorials.success.resetBtn')}
        </StandardButton>
      )}
    </div>
  );
};
