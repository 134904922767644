import React from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

/**
 * Displays a temporary loading message when awaiting response from
 * an API
 */
export const LoadingMessage = () => {
  const { t } = useTranslation();

  return <Alert variant="primary">{t('loadingMessage.loading')}</Alert>;
};

interface ILoadingModal {
  /**
   * Whether the modal is currently showing
   */
  show: boolean;
}
/**
 * Displays a temporary loading message as a Modal over the current
 * UI, for long-running processes that happen after a page has loaded.
 */
export const LoadingModal = ({ show }: ILoadingModal) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} backdrop="static" keyboard={false} centered>
      <Modal.Body>{t('loadingMessage.working')}</Modal.Body>
    </Modal>
  );
};
