import React, { useState } from 'react';
import { ActionEntry } from '@soulhx/fs-common';
import { useDeleteActionEntryMutation } from '../../../../services/ActionsService.js';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

interface IPlanItemToastBody {
  entry: ActionEntry;
}
export const PlanItemToastBody = ({ entry }: IPlanItemToastBody) => {
  const [deleteActionItem] = useDeleteActionEntryMutation();
  const [deleted, setDeleted] = useState<boolean>(false);
  const { t } = useTranslation(['plan']);

  return (
    <div>
      <p>{t('plan:item.toastBody.aeCreated', { displayName: entry.displayName })}</p>

      <p>
        {!deleted && (
          <Button
            variant="outline-success"
            onClick={() => {
              deleteActionItem(entry.id)
                .unwrap()
                .then(() => {
                  setDeleted(true);
                });
            }}
          >
            {t('plan:item.toastBody.undoBtn')}
          </Button>
        )}
        {deleted && <em>{t('plan:item.toastBody.removedMsg')}</em>}
      </p>
    </div>
  );
};
