import { gt, valid, inc, parse } from 'semver';

/**
 * Helper function to determine whether a version number is
 * semantically valid; version numbers must be in the form
 * `X.Y.Z` (X == Major, Y == Minor, Z == Patch).
 *
 * Just a wrapper over semver's `valid()` function.
 *
 * @param versionNumber The version number to be checked
 * @returns Boolean: valid or not
 */
export const isVersionValid = (versionNumber: string): boolean => {
  const isValid = valid(versionNumber);

  if (isValid !== null) {
    return true;
  }

  return false;
};

/**
 * Takes in a new version number and an old version number, and
 * indicates whether the new one is greater than the old one.
 *
 * Just a wrapper over semver's `gt()` function.
 *
 * @param version2String The new version number
 * @param version1String The old version number
 * @returns Boolean: it is greater or not
 */
export const v2GTv1 = (version2String: string, version1String: string): boolean => {
  return gt(version2String, version1String);
};

/**
 * Helper function to increment a version number. Simply a wrapper
 * over the semver `inc()` function. Returns `undefined` if the
 * original version number was invalid. (Semver returned `null`
 * but `undefined` seems more in line with other code in SoulHX.)
 *
 * @param oldVersion Original version number
 * @param type `major` `minor`  or `patch`
 * @returns New version number or `undefined`
 */
export const incrementVersion = (oldVersion: string, type: 'major' | 'minor' | 'patch'): string | undefined => {
  const newVer = inc(oldVersion, type);
  return newVer || undefined;
};

/**
 * Takes a semver version number and returns as small a rendition of
 * it as possible; mostly just a matter of removing patch and/or minor
 * if possible.
 *
 * @example
 * minifiedVersion('1.0.0') // '1'
 * minifiedVersion('1.1.0') // '1.1'
 * minifiedVersion('1.0.1') // '1.0.1'
 * minifiedVersion('1.1.1') // '1.1.1'
 * minifiedVersion('1.0.0') // '1'
 *
 * @param version The original version
 * @returns As minimal a version number as possible
 */
export const minifiedVersion = (version: string): string => {
  const parseResponse = parse(version);

  if (!parseResponse) return '';

  if (parseResponse.patch === 0 && parseResponse.minor === 0) return parseResponse.major.toString();

  if (parseResponse.patch === 0) return `${parseResponse.major}.${parseResponse.minor}`;

  return parseResponse.format();
};
