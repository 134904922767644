import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

const useIsomorphicLayout = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useIsomorphicLayout(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
};

export const useWindowWidth = () => {
  const hasMounted = useHasMounted();
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    if (!hasMounted) return;
    setWidth(window.innerWidth);
  }, [hasMounted, setWidth]);

  useIsomorphicLayout(() => {
    if (hasMounted) {
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasMounted, handleResize]);

  return width;
};
