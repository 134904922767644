import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './Store.js';

export interface IPersistedUISlice {
  token: string;
  collapsedPrayerLists: string[];
  showHoverText: boolean;
}

const initialState: IPersistedUISlice = { token: '', collapsedPrayerLists: [], showHoverText: false };

export const persistedUISlice = createSlice({
  name: 'persistedUI',
  initialState: initialState,
  reducers: {
    updateToken: (state: IPersistedUISlice, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    resetPersistedUIState: (state: IPersistedUISlice) => {
      state.token = '';
    },
    addCollapsedPrayerList: (state: IPersistedUISlice, action: PayloadAction<string>) => {
      state.collapsedPrayerLists.push(action.payload);
    },
    deleteCollapsedPrayerList: (state: IPersistedUISlice, action: PayloadAction<string>) => {
      const newArr = state.collapsedPrayerLists.slice().filter((item) => item !== action.payload);
      state.collapsedPrayerLists = newArr;
    },
    setShowHoverText: (state: IPersistedUISlice, action: PayloadAction<boolean>) => {
      state.showHoverText = action.payload;
    },
  },
});

export const {
  updateToken,
  resetPersistedUIState,
  addCollapsedPrayerList,
  deleteCollapsedPrayerList,
  setShowHoverText,
} = persistedUISlice.actions;
export default persistedUISlice.reducer;

export const getToken = (state: RootState) => {
  return state.persistedUI.token;
};

export const getCollapsedPrayerLists = (state: RootState) => {
  return state.persistedUI.collapsedPrayerLists;
};

export const getShowHoverText = (state: RootState) => {
  return state.persistedUI.showHoverText;
};
