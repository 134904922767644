import React from 'react';
import { UserSetting } from '@soulhx/fs-common';
import { useTranslation } from 'react-i18next';
import { Button, Form } from 'react-bootstrap';
import { handleInit } from '../../hooks/HandleInit.js';
import { useBulkUserSettings } from '../../hooks/BulkSettings.js';

export const HomeSettings = () => {
  const { t } = useTranslation();
  const { settings, settingsLoading, settingsError, bulkUpdate } = useBulkUserSettings();

  const resp = handleInit([settingsLoading], [settingsError]);
  if (resp) return resp;

  return (
    <Form.Group>
      <p>
        <Form.Text>{t('home.settings.resetHelpTextBtn.label')}</Form.Text>
      </p>
      <Button
        variant="secondary"
        onClick={() => {
          const itemsToFlip: UserSetting[] = settings
            .filter(
              (s) =>
                s.settingId.startsWith('helpText.home') ||
                s.settingId.startsWith('helpText.settings') ||
                s.settingId.startsWith('helpText.editTemplates')
            )
            .map((s) => ({ settingId: s.settingId, settingBoolValue: true }));
          bulkUpdate(itemsToFlip);
        }}
      >
        {t('home.settings.resetHelpTextBtn.text')}
      </Button>
    </Form.Group>
  );
};

export default HomeSettings;
