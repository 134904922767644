/**
 * Standard set of error codes used throughout the APIs in this
 * application. Makes troubleshooting from the logs easier.
 */
export enum StandardErrorCodes {
  DatabaseProblem = 1,
  NotFound,
  SomethingWentWrong,
  InvalidData,
  BEConfigError,
  DBConnectionError,
  CORSError,
  UserInfoExists,
  SystemError,
  ErrorCreatingUser,
  LoginError,
  UnauthorizedUser,
  UserUpdate,
  InvalidSettings,
  NonAdmin,
  InvalidPassage,
  HealthCheck,
  InvalidPlanStatus,
  PlanVersion,
  InvalidPlan,
  InvalidDaysForPlan,
  IPForPublishedPlan,
  LogoutError,
  NoteSearchError,
  NonPrayerListError,
  InsertItem,
  DeleteItem,
  UpdateItem,
  InvalidOsisForBounds,
  InvalidOsisForVerses,
  EmailSend,
  NoRegisteredUser,
  InvalidGraphData,
  InvalidGraphEntriesLength,
  InvalidSetting,
  DuplicateServerMessageLang,
}

/**
 * Interface used for JSON error responses from APIs
 */
export interface ErrorResponse {
  errorCode: StandardErrorCodes;
  errors: {
    message: string;
    field?: string;
  }[];
}

/**
 * Standard type used for errors thrown from APIs. Implemented
 * as an **abstract** class, with the intent that specific
 * exceptions will extend this class and implement the
 * {@link serializeErrors} and {@link serializeInternalError}
 * methods with case-specific implementations.
 */
export abstract class CustomError extends Error {
  abstract statusCode: number;
  abstract errorCode: StandardErrorCodes;

  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, CustomError.prototype);
  }

  /**
   * Called to get an {@link ErrorResponse} object with details
   * about the exception, and a list of 0 or more (almost always
   * at least 1) specific errors being raised.
   *
   * @returns An {@link ErrorResponse} object with details about the error
   */
  abstract serializeErrors(): ErrorResponse;

  /**
   * Gets details about the error in string form, typically for
   * logging purposes. Not called directly by outside code,
   * the {@link serializeErrorsToString} function is better
   * for that, since it returns the error code along with the
   * response from this function (hence the "internal" in the
   * name).
   *
   * @returns String representation of the error
   */
  abstract serializeInternalError(): string;

  /**
   * Returns a string representation of the exception. On a case-
   * by-case basis specific exceptions can decide what that means.
   *
   * @returns A string representing the exception
   */
  serializeErrorsToString(): string {
    return `Error code ${this.errorCode}. ` + this.serializeInternalError();
  }
}
