import React from 'react';
import { Badge, ListGroup, Placeholder } from 'react-bootstrap';
import { StandardButton } from '../../../common/ui/buttons/index.js';
import { CalendarEventFill, Check } from 'react-bootstrap-icons';

interface ICurrentRPPlaceholder {
  showTitle?: boolean;
}
export const CurrentRPPlaceholder = ({ showTitle = false }: ICurrentRPPlaceholder) => {
  return (
    <>
      {showTitle && (
        <h4 className="placholder-wave">
          <Placeholder xs="6" />
        </h4>
      )}
      <h4 className="d-flex justify-content-center placeholder-wave">
        <StandardButton type="left" disabled className="placeholder" />
        <Placeholder xs="3" />
        <StandardButton type="right" disabled className="placeholder" />
      </h4>
      <ListGroup>
        <ListGroup.Item>
          <Placeholder>
            <Placeholder xs="10" />
          </Placeholder>
          <div>
            <Check className="placeholder" />
            <Placeholder xs="3" />
            <Badge pill className="mx-1">
              <Placeholder xs="1" />
            </Badge>
          </div>
          <div>
            <StandardButton type="complete" disabled className="placeholder" />
            <StandardButton type="pin" disabled className="placeholder" />
            <CalendarEventFill className="placeholder" />
          </div>
        </ListGroup.Item>

        <ListGroup.Item variant="success">
          <Placeholder>
            <Placeholder xs="10" />
          </Placeholder>
          <div>
            <StandardButton type="complete" disabled className="placeholder" />
            <StandardButton type="pin" disabled className="placeholder" />
            <CalendarEventFill className="placeholder" />
          </div>
        </ListGroup.Item>
      </ListGroup>
    </>
  );
};
