import React, { useState, Suspense, lazy } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { Login } from './Login.js';
import { useTranslation } from 'react-i18next';
import { AnonHeader } from '../common/AnonHeader.js';
const RegisterUserPage = lazy(() => import('./RegisterUser.js'));

export const LoginRegister = () => {
  const [activeTabKey, setActiveTabKey] = useState('login');
  const { t } = useTranslation();

  return (
    <>
      <AnonHeader />
      <Container>
        <h1>{t('login.title')}</h1>
        <Tabs activeKey={activeTabKey} onSelect={(t) => setActiveTabKey(t || 'login')}>
          <Tab eventKey="login" title={t('login.loginTab')}>
            <Login />
          </Tab>
          <Tab eventKey="register" title={t('login.registerTab')}>
            <Suspense fallback={'...'}>
              <RegisterUserPage />
            </Suspense>
          </Tab>
        </Tabs>
      </Container>
    </>
  );
};

export default LoginRegister;
