import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useGetAllTemplatesQuery } from '../../../services/TemplatesService.js';
import { Template } from './Template.js';
import { useTranslation } from 'react-i18next';
import { handleInit } from '../../../hooks/HandleInit.js';
import { ShxHelpText } from '../ShxHelpText.js';

interface ITemplateEditor {
  show: boolean;
  onHide(): void;
}
export const TemplateEditor = ({ show, onHide }: ITemplateEditor) => {
  const { data, error, isLoading } = useGetAllTemplatesQuery();
  const { t } = useTranslation();

  const resp = handleInit([isLoading], [error]);
  if (resp) return resp;

  return (
    <Modal show={show} onHide={onHide} fullscreen="md-down">
      <Modal.Header closeButton>{t('templateEditor.title')}</Modal.Header>
      <Modal.Body>
        {data!.length > 0 && (
          <>
            <ShxHelpText pageName="editTemplates" textName="welcomeMsg" />

            <h4>{t('templateEditor.existingTitle')}</h4>
            {data!.map((t) => (
              <Template key={t.id} template={t} />
            ))}
          </>
        )}
        <Template />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{t('templateEditor.closeBtn')}</Button>
      </Modal.Footer>
    </Modal>
  );
};
