/**
 * Data type for working with *types* of actions (as opposed to
 * Action Entries, covered by {@link ActionEntry}).
 *
 * In this case, rather than creating a "BaseActionType" interface,
 * just made the `id` field optional.
 */
export interface ActionType {
  displayName: string;
  isDefault: boolean;
  isAvailable?: boolean;
  id?: string;
}

/**
 * Data type for "default" action types, used for administration
 */
export interface DefaultActionType {
  displayName: string;
  id: string;
  specialActionId?: string;
}

/**
 * Base fields for an Action Entry, used when **creating** one
 * (when things like the `id` and `entryDate` haven't been
 * created by the API yet).
 */
export interface BaseActionEntry {
  actionType: string;
  completed: boolean;
  entryDate?: string;
}

/**
 * A full Action Entry, extending {@link BaseActionEntry} to
 * add fields captured by the API.
 */
export interface ActionEntry extends BaseActionEntry {
  id: string;
  entryDate: string;
  /**
   * API populates this from the Action Type
   */
  displayName: string;
}

/**
 * Simple interface just for the fields required to **update**
 * an action entry via the API.
 */
export interface UpdateActionEntry {
  id: string;
  entryDate: string;
}

/**
 * IDs for "special" action types used in SoulHX, that are
 * available for every user (whether used or not).
 *
 * e.g.
 *
 * * For reading plans, these IDs can be used to automatically
 * set an action entry for reading the Bible
 * * For statistics, Scripture-based entries can be "rolled up"
 * into anything to do with the Bible, or Old vs. New Testaments,
 * etc.
 */
export interface SpecialActionTypeIds {
  /**
   * Read a short Old Testament passage
   */
  shortOT: string;

  /**
   * Read a long Old Testament passage
   */
  longOT: string;

  /**
   * Read a short New Testament passage
   */
  shortNT: string;

  /**
   * Read a long New Testament passage
   */
  longNT: string;

  /**
   * Prayed
   */
  prayer: string;

  /**
   * Read a Christian book
   */
  book: string;

  /**
   * Went to Church
   */
  church: string;

  /**
   * Listened to a Christian podcast
   */
  podcast: string;

  /**
   * Journalled
   */
  journal: string;

  /**
   * Read Scripture
   */
  scripture: string;
}

/**
 * Options for how much data should be shown in visualisations
 */
export enum StatsDurations {
  AllTime = 'alltime',
  Year = 'year',
  Month = 'month',
  TwoWeeks = '2weeks',
  Week = 'week',
}

/**
 * Interface for getting amalgamated stats
 */
export interface ActionStats {
  fromDate: string;
  toDate: string;
  actions: { action: ActionType; count: number }[];
}

/**
 * Interface for getting stats broken down by day
 */
export interface DailyActionStats {
  date: string;
  actions: { action: ActionType; count: number }[];
}

/**
 * Interface for a "Data point" -- that is, an action that should be
 * visualised in a graph
 */
export interface DataPoint {
  actionId: string;
  displayName: string;
  colourIndex?: number;
}

/**
 * Data type for data that is specific to a "binary" (either/or) graph
 */
export type BinaryProps = {
  type: 'binary';
  actionType: DataPoint;
  negativeDisplayName: string;
  negativeColourIndex?: number;
};

/**
 * Data type for data that is specific to a "trend" graph
 */
export type TrendProps = {
  type: 'trend';
  chartType: 'line' | 'area' | 'bar';
  selectedActionTypes: DataPoint[];
};

/**
 * Data type for data that is specific to a Comparison graph
 */
export type ComparisonProps = {
  type: 'comparison';
  chartType: 'pie' | 'bar';
  selectedActionTypes: DataPoint[];
};

/**
 * Data type for "treemap" graphs
 */
export type TreemapProps = {
  type: 'tree';
  selectedActionTypes: DataPoint[];
  mediaTitle: string;
  scriptureTitle: string;
};

/**
 * Data type for information for a visualisation, of any of the
 * defined types
 */
export type VisualisationProps = {
  title: string;
  id?: string;
} & (BinaryProps | TrendProps | ComparisonProps | TreemapProps);
