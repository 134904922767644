import React, { useMemo } from 'react';
import { ExclamationDiamondFill, InfoCircleFill, CheckSquareFill } from 'react-bootstrap-icons';
import { ToastType } from './types.js';

interface IToastIcon {
  type: ToastType;
  className?: string;
}
export const ToastIcon = ({ type, className = '' }: IToastIcon) => {
  const icons = useMemo(
    () => ({
      [ToastType.Info]: <InfoCircleFill className={className} />,
      [ToastType.Danger]: <ExclamationDiamondFill className={className} />,
      [ToastType.Dark]: <InfoCircleFill className={className} />,
      [ToastType.Light]: <InfoCircleFill className={className} />,
      [ToastType.Primary]: <InfoCircleFill className={className} />,
      [ToastType.Secondary]: <InfoCircleFill className={className} />,
      [ToastType.Success]: <CheckSquareFill className={className} />,
      [ToastType.Warning]: <ExclamationDiamondFill className={className} />,
      [ToastType.None]: <InfoCircleFill className={className} />,
    }),
    [className]
  );

  const theIcon = useMemo(() => {
    return icons[type] || <InfoCircleFill className={className} />;
  }, [type, className, icons]);

  return theIcon;
};
