import React from 'react';
import { StandardButton } from '../../ui/buttons/index.js';
import i18n from '../../../../i18n.js';

interface IFullScreenButton {
  isFullScreen: boolean;
  stateSetter(fs: boolean): void;
}
export const FullScreenButton = ({ isFullScreen, stateSetter }: IFullScreenButton) => {
  return (
    <StandardButton type="fullscreen" done={isFullScreen} onClick={() => stateSetter(!isFullScreen)}>
      {i18n.t(isFullScreen ? 'mdb.normalViewBtn' : 'mdb.fullscreenViewBtn', { ns: 'mde' })}
    </StandardButton>
  );
};
