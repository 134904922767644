import React, { MouseEventHandler, ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { TooltipElement } from '../TooltipElement.js';

/**
 * Interface used for any generic SHX buttons; all properties are
 * optional, as they may or may not get used for different buttons
 * (e.g. `itemType` only applies in some cases).
 */
export interface ISHXButton {
  /**
   * Situation-specific class name(s) to be added to the button, *in
   * addition* to what `<SHXButton>` itself uses
   */
  className?: string;

  /**
   * Situation-specific Bootstrap **variant** (e.g. primary,
   * outline-secondary, ...) to be used for the Button
   */
  variant?: string;

  /**
   * Click handler function for the button
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;

  /**
   * Disabled indicator
   */
  disabled?: boolean;

  /**
   * Tooltip to show for the button (overrides `itemType` setting --
   * if `tooltip` is set, `itemType` is ignored)
   */
  tooltip?: string;

  /**
   * Type of item. Used for generating a tooltip for some types of
   * button, if specific tooltip isn't provided.
   */
  itemType?: string;

  /**
   * Indicates if this is a form submit button (defaults false)
   */
  submit?: boolean;

  /**
   * Child element to be rendered for the button; for SHX, this
   * is *typically* an icon, but doesn't have to be.
   */
  children?: ReactNode;

  /**
   * Optional size (defaults to normal)
   */
  size?: 'sm' | 'lg' | undefined;

  /**
   * Used to set underlying `aria-label`
   */
  ariaLabel?: string;
}
/**
 * Renders a generic button, with typical **SHX styling**. May or may
 * not include a tooltip (via the `<TooltipElement>` component).
 */
export const SHXButton = ({
  className,
  variant,
  onClick,
  disabled,
  tooltip,
  children,
  submit = false,
  size = undefined,
  ariaLabel,
}: ISHXButton) => {
  const theAriaLabel = ariaLabel ? ariaLabel : typeof children === 'string' ? children : tooltip;

  if (tooltip) {
    return (
      <TooltipElement tooltip={tooltip}>
        <Button
          aria-label={theAriaLabel}
          className={`border-0 text-uppercase fw-bold ${className || ''}`}
          variant={variant}
          onClick={onClick}
          disabled={disabled}
          type={submit ? 'submit' : 'button'}
          size={size}
        >
          {children}
        </Button>
      </TooltipElement>
    );
  }

  return (
    <Button
      aria-label={theAriaLabel}
      className={`border-0 text-uppercase fw-bold ${className || ''}`}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      size={size}
    >
      {children}
    </Button>
  );
};
