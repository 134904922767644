import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './Store.js';
import { DateTime } from 'luxon';
import { UserSetting } from '@soulhx/fs-common';
import { ClientPassage } from '../dm/passage.js';

interface PaginationObject {
  id: string;
}

export interface PaginationPageData {
  pageName: string;
  pageOfData?: number | undefined;
  hasMoreData: boolean;
  items: PaginationObject[];
}

export interface IUISlice {
  dateShowingInActions?: string;
  selectedPassage?: ClientPassage;
  selectedNote?: string;
  dateForReadingPlan?: string;
  tempPassages: ClientPassage[];
  notesFilter: string;
  newVersionReload: boolean;
  userSettings: UserSetting[];
  prayerSearchString: string;
  editPrayerTags: boolean;
  filteredPrayerTags: string[];
  filteredPassageTags: string[];
  expandedPassages: string[];
  displayedHelpTexts: string[];
  paginatedData: PaginationPageData[];
  showAllHelpText: boolean;
}

const initialState: IUISlice = {
  tempPassages: [],
  newVersionReload: false,
  userSettings: [],
  prayerSearchString: '',
  editPrayerTags: false,
  filteredPrayerTags: [],
  filteredPassageTags: [],
  expandedPassages: [],
  displayedHelpTexts: [],
  paginatedData: [],
  notesFilter: '',
  showAllHelpText: false,
};

export const uiSlice = createSlice({
  name: 'ui3',
  initialState: initialState,
  reducers: {
    updateDateShowingInActions: (state: IUISlice, action: PayloadAction<string>) => {
      state.dateShowingInActions = action.payload;
    },
    updateSelectedPassage: (state: IUISlice, action: PayloadAction<ClientPassage | undefined>) => {
      state.selectedPassage = action.payload;
    },
    updateSelectedNote: (state: IUISlice, action: PayloadAction<string>) => {
      state.selectedNote = action.payload;
    },
    updateNewVersionReload: (state: IUISlice, action: PayloadAction<boolean>) => {
      state.newVersionReload = action.payload;
    },
    updateDateForReadingPlan: (state: IUISlice, action: PayloadAction<string>) => {
      state.dateForReadingPlan = action.payload;
    },
    setEditPrayerTags: (state: IUISlice, action: PayloadAction<boolean>) => {
      state.editPrayerTags = action.payload;
    },
    removeTempPassage: (state: IUISlice, action: PayloadAction<string>) => {
      const newList = state.tempPassages.slice().filter((item) => item.id !== action.payload);

      state.tempPassages = newList;
    },
    updateTempPassage: (state: IUISlice, action: PayloadAction<ClientPassage>) => {
      const list = state.tempPassages.slice();
      const index = list.findIndex((item) => item.id === action.payload.id);
      if (index < 0) return;

      list[index].osis = action.payload.osis;
      list[index].version = action.payload.version;
      list[index].hashtags = action.payload.hashtags;

      state.tempPassages = list;
    },
    addTempPassage: (state: IUISlice, action: PayloadAction<ClientPassage>) => {
      const newList: ClientPassage[] = [action.payload].concat(state.tempPassages);

      state.tempPassages = newList;
    },
    removeSelectedTempPassages: (state: IUISlice, action: PayloadAction<string[]>) => {
      const newList = state.tempPassages.filter((item) => !action.payload.includes(item.id));

      state.tempPassages = newList;
    },
    setUserSetting: (state: IUISlice, action: PayloadAction<UserSetting>) => {
      const newArr = state.userSettings.filter((s) => s.settingId !== action.payload.settingId);
      newArr.push(action.payload);
      state.userSettings = newArr;
    },
    setUserSettings: (state: IUISlice, action: PayloadAction<UserSetting[]>) => {
      state.userSettings = action.payload;
    },
    setPrayerSearchString: (state: IUISlice, action: PayloadAction<string>) => {
      state.prayerSearchString = action.payload;
    },
    addFilteredPrayerTag: (state: IUISlice, action: PayloadAction<string>) => {
      state.filteredPrayerTags.push(action.payload);
    },
    deleteFilteredPrayerTag: (state: IUISlice, action: PayloadAction<string>) => {
      const newArr = state.filteredPrayerTags.filter((item) => item !== action.payload);
      state.filteredPrayerTags = newArr;
    },
    clearFilteredPrayerTags: (state: IUISlice) => {
      state.filteredPrayerTags = [];
    },
    addFilteredPassageTag: (state: IUISlice, action: PayloadAction<string>) => {
      state.filteredPassageTags.push(action.payload);
    },
    deleteFilteredPassageTag: (state: IUISlice, action: PayloadAction<string>) => {
      const newArr = state.filteredPassageTags.filter((item) => item !== action.payload);
      state.filteredPassageTags = newArr;
    },
    clearFilteredPassageTags: (state: IUISlice) => {
      state.filteredPassageTags = [];
    },
    addExpandedPassage: (state: IUISlice, action: PayloadAction<string>) => {
      state.expandedPassages.push(action.payload);
    },
    deleteExpandedPassage: (state: IUISlice, action: PayloadAction<string>) => {
      const newArr = state.expandedPassages.filter((item) => item !== action.payload);
      state.expandedPassages = newArr;
    },
    addDisplayedHT: (state: IUISlice, action: PayloadAction<string>) => {
      state.displayedHelpTexts.push(action.payload);
    },
    deleteDisplayedHT: (state: IUISlice, action: PayloadAction<string>) => {
      const newArr = state.displayedHelpTexts.slice();
      for (let i = 0; i < newArr.length; i++) {
        /*
         * Replace it with an empty string instead of deleting it, so
         * we're not adding more help text in this session
         */
        if (newArr[i] === action.payload) newArr[i] = '';
      }
      state.displayedHelpTexts = newArr;
    },
    clearDisplayedHT: (state: IUISlice) => {
      state.displayedHelpTexts = [];
    },
    setPaginationData: (state: IUISlice, action: PayloadAction<PaginationPageData>) => {
      let newArray = state.paginatedData.slice();
      newArray = newArray.filter((item) => item.pageName !== action.payload.pageName);
      newArray.push(action.payload);
      state.paginatedData = newArray;
    },
    clearPaginationData: (state: IUISlice, action: PayloadAction<string>) => {
      let newArray = state.paginatedData.slice();
      newArray = newArray.filter((item) => item.pageName !== action.payload);
      const newPageData: PaginationPageData = {
        pageName: action.payload,
        hasMoreData: true,
        items: [],
      };
      newArray.push(newPageData);
      state.paginatedData = newArray;
    },
    setNotesFilter: (state: IUISlice, action: PayloadAction<string>) => {
      state.notesFilter = action.payload;
    },
    updateShowAllHelpText: (state: IUISlice, action: PayloadAction<boolean>) => {
      state.showAllHelpText = action.payload;
    },
    resetUIState: (state: IUISlice) => {
      state.dateForReadingPlan = initialState.dateForReadingPlan;
      state.dateShowingInActions = initialState.dateShowingInActions;
      state.selectedNote = initialState.selectedNote;
      state.selectedPassage = initialState.selectedPassage;
      state.notesFilter = initialState.notesFilter;
      state.userSettings = [];
      state.prayerSearchString = '';
      state.filteredPrayerTags = [];
      state.filteredPassageTags = [];
      state.expandedPassages = [];
      state.displayedHelpTexts = [];
      state.paginatedData = [];
      state.showAllHelpText = false;
    },
  },
});

export const {
  updateDateShowingInActions,
  updateSelectedPassage,
  updateSelectedNote,
  updateDateForReadingPlan,
  resetUIState,
  removeTempPassage,
  updateTempPassage,
  removeSelectedTempPassages,
  addTempPassage,
  updateNewVersionReload,
  setUserSetting,
  setUserSettings,
  setPrayerSearchString,
  setEditPrayerTags,
  addFilteredPrayerTag,
  deleteFilteredPrayerTag,
  clearFilteredPrayerTags,
  addExpandedPassage,
  deleteExpandedPassage,
  addDisplayedHT,
  deleteDisplayedHT,
  clearDisplayedHT,
  addFilteredPassageTag,
  deleteFilteredPassageTag,
  clearFilteredPassageTags,
  clearPaginationData,
  setPaginationData,
  setNotesFilter,
  updateShowAllHelpText,
} = uiSlice.actions;
export default uiSlice.reducer;

export const getSelectedPassage = (state: RootState): ClientPassage | undefined => {
  return state.ui.selectedPassage;
};

export const getPrayerSearchString = (state: RootState): string => {
  return state.ui.prayerSearchString;
};

export const getEditPrayerTags = (state: RootState): boolean => {
  return state.ui.editPrayerTags;
};

export const getTempPassages = (state: RootState): ClientPassage[] => {
  return state.ui.tempPassages;
};

export const getSelectedNote = (state: RootState): string => {
  return state.ui.selectedNote || '';
};

export const getNewVersionReload = (state: RootState): boolean => {
  return state.ui.newVersionReload;
};

export const getDateShowingInActions = (state: RootState): string => {
  return state.ui.dateShowingInActions && state.ui.dateShowingInActions.length > 0
    ? state.ui.dateShowingInActions
    : DateTime.now().toISODate()!;
};

export const getDateForReadingPlan = (state: RootState): string => {
  return state.ui.dateForReadingPlan && state.ui.dateForReadingPlan.length > 0
    ? state.ui.dateForReadingPlan
    : DateTime.now().toISODate()!;
};

export const getUserSettings = (state: RootState) => {
  return state.ui.userSettings;
};

export const getFilteredPrayerTags = (state: RootState) => {
  return state.ui.filteredPrayerTags;
};

export const getFilteredPassageTags = (state: RootState) => {
  return state.ui.filteredPassageTags;
};

export const getExpandedPassages = (state: RootState) => {
  return state.ui.expandedPassages;
};

export const getDisplayedHelpTexts = (state: RootState) => {
  return state.ui.displayedHelpTexts;
};

export const getPaginationData = (state: RootState) => {
  return state.ui.paginatedData;
};

export const getNotesFilter = (state: RootState) => {
  return state.ui.notesFilter;
};

export const getShowAllHelpText = (state: RootState) => {
  return state.ui.showAllHelpText;
};
