import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, updateToken } from '../stores/PersistedUI.js';
import { useRefreshTokenMutation } from '../services/SecurityService.js';
import { LoginRegister } from './users/LoginRegister.js';
import { NewVersionReload } from './common/NewVersionReload.js';
import { UserSetting } from '@soulhx/fs-common';
import { getUserSettings, setUserSettings } from '../stores/UISlice.js';
import { Home } from './home/Home.js';
import { Header } from './common/Header.js';
import { FloatingActionButtons } from './common/actionbtns/FloatingActionButtons.js';
const Pray = lazy(() => import('./pray/Pray.js'));
const Do = lazy(() => import('./do/Do.js'));
const Read = lazy(() => import('./read/Read.js'));
const Plans = lazy(() => import('./plans/Plans.js'));
const EditPlan = lazy(() => import('./plans/edit/EditPlan.js'));
const SizeIndicator = lazy(() => import('./common/SizeIndicator.js'));
const Users = lazy(() => import('./admin/users/UserList.js'));
const ServerMessages = lazy(() => import('./admin/serverMessages/EditServerMessages.js'));
const ChangePwd = lazy(() => import('./users/ChangePwd.js'));
const FourOhFour = lazy(() => import('./common/FourOhFour.js'));
const CreateAccount = lazy(() => import('./users/CreateAccount.js'));
const CustomizeActions = lazy(() => import('./do/CustomizeActions.js'));
const ConfigVis = lazy(() => import('./do/stats/ConfigVis.js'));
const MainTutorial = lazy(() => import('./main-tutorial/MainTutorial.js'));
const MarkdownTutorial = lazy(() => import('./common/md/MDTutorial.js'));
const ViewPrayerItem = lazy(() => import('./pray/ViewItem.js'));
const MarkdownTester = lazy(() => import('./admin/md-tester/MarkdownTester.js')); //TODO: remove

const syncLogout = (event: StorageEvent) => {
  if (event.key === 'logout') {
    window.location.reload();
  }
};

export const App = () => {
  const token: string = useSelector(getToken);
  const dispatch = useDispatch();
  const [refreshToken] = useRefreshTokenMutation();
  const allSettings = useSelector(getUserSettings);

  useEffect(() => {
    if (!token || token.length < 1 || token === '') return;

    if (allSettings.length < 1) {
      fetch('/api/auth/u/s', {
        headers: { Authorization: `bearer ${token}`, 'Content-Type': 'application/json' },
      }).then(async (response) => {
        if (response.status === 401) {
          dispatch(updateToken(''));
          return;
        }
        if (!response.ok) return;

        const settings: UserSetting[] = await response.json();
        dispatch(setUserSettings(settings));
      });
    }

    const verifyUser = () => {
      if (token.length > 0) {
        refreshToken()
          .unwrap()
          .then((response) => {
            dispatch(updateToken(response.token));
          })
          .catch((err) => {
            if (err.status === 401) dispatch(updateToken(''));
          });
      }

      setTimeout(verifyUser, 5 * 60 * 1000);
    };

    verifyUser();

    window.addEventListener('storage', syncLogout);

    return () => {
      window.removeEventListener('storage', syncLogout);
    };
  }, [dispatch, refreshToken, token, allSettings]);

  return token === '' ? (
    <Suspense fallback="...">
      <BrowserRouter future={{ v7_startTransition: true }}>
        <Routes>
          <Route path="/changePwd" element={<ChangePwd />} />
          <Route path="/changePwd/:pwdToken" element={<ChangePwd />} />
          <Route path="/createAccount" element={<CreateAccount />} />
          <Route path="/createAccount/:token" element={<CreateAccount />} />
          <Route path="*" element={<LoginRegister />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  ) : (
    <div>
      <Suspense fallback="...">
        <BrowserRouter future={{ v7_startTransition: true }}>
          <div>
            <Header />
            <NewVersionReload />
            <FloatingActionButtons />
            <Routes>
              <Route path="/pray" element={<Pray />} />
              <Route path="/pray/:prayerId" element={<ViewPrayerItem />} />
              <Route path="/do" element={<Do />} />
              <Route path="/do/config" element={<CustomizeActions />} />
              <Route path="/do/configvis" element={<ConfigVis />} />
              <Route path="/read" element={<Read />} />
              <Route path="/plans" element={<Plans />} />
              <Route path="/plans/edit" element={<EditPlan />} />
              <Route path="/plans/edit/:planId" element={<EditPlan />} />
              <Route path="/admin/users" element={<Users />} />
              <Route path="/admin/serverMessages" element={<ServerMessages />} />
              {/* TODO: remove */}
              <Route path="/admin/mdTester" element={<MarkdownTester />} />
              <Route path="/changePwd" element={<ChangePwd />} />
              <Route path="/changePwd/:pwdToken" element={<ChangePwd />} />
              <Route path="/tutorial" element={<MainTutorial />} />
              <Route path="mdtutorial" element={<MarkdownTutorial />} />
              <Route path="/" element={<Home />} />
              <Route path="*" element={<FourOhFour />} />
            </Routes>
            <SizeIndicator />
          </div>
        </BrowserRouter>
      </Suspense>
    </div>
  );
};
