import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import uiReducer, { resetUIState } from './UISlice.js';
import persistedUIReducer, { resetPersistedUIState } from './PersistedUI.js';
import { userApi } from '../services/UserService.js';
import { registrationApi } from '../services/RegistrationService.js';
import { actionApi } from '../services/ActionsService.js';
import { passageApi } from '../services/PassageService.js';
import { notesApi } from '../services/NotesService.js';
import { securityApi, loginApi } from '../services/SecurityService.js';
import { plansApi } from '../services/PlansService.js';
import { verseApi } from '../services/VerseService.js';
import { adminApi } from '../services/AdminService.js';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { templatesApi } from '../services/TemplatesService.js';
import { statsApi } from '../services/StatsService.js';
import { prayerApi } from '../services/PrayerService.js';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration.js';
import { persistMigrate, persistVersion } from './PersistMigrations.js';
import { commsApi } from '../services/CommsService.js';

const refreshCacheAndReload = () => {
  if ('serviceWorker' in navigator) {
    serviceWorkerRegistration.unregister();
    caches
      .keys()
      .then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      })
      .then(() => {
        serviceWorkerRegistration.register();
      });
  }
};

const reducers = combineReducers({
  [userApi.reducerPath]: userApi.reducer,
  [registrationApi.reducerPath]: registrationApi.reducer,
  [actionApi.reducerPath]: actionApi.reducer,
  [passageApi.reducerPath]: passageApi.reducer,
  [notesApi.reducerPath]: notesApi.reducer,
  [securityApi.reducerPath]: securityApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer,
  [plansApi.reducerPath]: plansApi.reducer,
  [verseApi.reducerPath]: verseApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [templatesApi.reducerPath]: templatesApi.reducer,
  [statsApi.reducerPath]: statsApi.reducer,
  [prayerApi.reducerPath]: prayerApi.reducer,
  [commsApi.reducerPath]: commsApi.reducer,
  ui: uiReducer,
  persistedUI: persistedUIReducer,
});

const persistConfig = {
  key: 'root',
  version: persistVersion,
  storage,
  whitelist: ['persistedUI'],
  migrate: persistMigrate,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER] } })
      .concat(userApi.middleware)
      .concat(registrationApi.middleware)
      .concat(actionApi.middleware)
      .concat(passageApi.middleware)
      .concat(notesApi.middleware)
      .concat(securityApi.middleware)
      .concat(loginApi.middleware)
      .concat(plansApi.middleware)
      .concat(verseApi.middleware)
      .concat(adminApi.middleware)
      .concat(templatesApi.middleware)
      .concat(statsApi.middleware)
      .concat(prayerApi.middleware)
      .concat(commsApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

setupListeners(store.dispatch);

/**
 * Clears cached data from every cache, to be used for logout
 * scenarios.
 */
export const clearAllCachedData = () => {
  store.dispatch(resetUIState());
  store.dispatch(resetPersistedUIState());
  store.dispatch(userApi.util.resetApiState());
  store.dispatch(registrationApi.util.resetApiState());
  store.dispatch(actionApi.util.resetApiState());
  store.dispatch(passageApi.util.resetApiState());
  store.dispatch(notesApi.util.resetApiState());
  store.dispatch(securityApi.util.resetApiState());
  store.dispatch(loginApi.util.resetApiState());
  store.dispatch(plansApi.util.resetApiState());
  store.dispatch(verseApi.util.resetApiState());
  store.dispatch(adminApi.util.resetApiState());
  store.dispatch(templatesApi.util.resetApiState());
  store.dispatch(statsApi.util.resetApiState());
  store.dispatch(prayerApi.util.resetApiState());
  store.dispatch(commsApi.util.resetApiState());
  refreshCacheAndReload();
};
