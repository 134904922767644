import React from 'react';
import { TutorialSubSectionContent } from '../../../dm/tutorial.js';
import { MarkdownSubSectionBody } from './MarkdownSubSection.js';
import { useTranslation } from 'react-i18next';
import { MDPreview } from '../md/index.js';

interface ISubsection {
  i18nNS: string;
  i18nSection: string;
  subSection: TutorialSubSectionContent;
  nextButton: JSX.Element;
}
export const Subsection = ({ i18nNS, i18nSection, subSection, nextButton }: ISubsection) => {
  const { t } = useTranslation([i18nNS]);

  if (subSection.type === 'simple') {
    if (subSection.displayComponent) {
      return <subSection.displayComponent nextButton={nextButton} />;
    }

    return (
      <>
        <MDPreview content={t(`${i18nNS}:${i18nSection}.${subSection.name}.intro`)} className="mb-3" />

        {nextButton}
      </>
    );
  }

  return (
    <MarkdownSubSectionBody
      i18nNS={i18nNS}
      i18nSection={i18nSection + '.' + subSection.name}
      subSection={subSection}
      nextButton={nextButton}
    />
  );
};
