import React from 'react';
import { useBulkUserSettings } from '../../hooks/BulkSettings.js';
import { handleInit } from '../../hooks/HandleInit.js';
import { Button, Col, Container, Modal, Row, Stack } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserSetting } from '@soulhx/fs-common';
import { MDPreview } from '../common/md/index.js';

export const TutorialMessages = () => {
  const { t } = useTranslation();
  const { settings, settingsLoading, settingsError, bulkUpdate } = useBulkUserSettings();

  const setTheDelayDate = (newDate: DateTime) => {
    const dateString = newDate.toISODate({})!;

    bulkUpdate([{ settingId: 'generalWizardDelayDate', settingStringValue: dateString }]);
  };

  const resp = handleInit([settingsLoading], [settingsError]);
  if (resp) return resp;

  const wizardSettings = settings.filter((setting) => setting.settingId.startsWith('wizard'));

  if (wizardSettings.every((setting) => setting.settingBoolValue === true)) return <></>;

  const delayDateString = settings.find((s) => s.settingId === 'generalWizardDelayDate')?.settingStringValue || '';
  const delayDate = DateTime.fromISO(delayDateString);
  const pastOrNoDate = !delayDate.isValid || delayDate.diffNow().milliseconds < 0;

  if (!pastOrNoDate) return <></>;

  interface IDelayBtn {
    numDays: number;
  }
  const DelayBtn = ({ numDays }: IDelayBtn) => {
    return (
      <Button variant="secondary" onClick={() => setTheDelayDate(DateTime.now().plus({ days: numDays }))}>
        {t('messages.tutorial.delayBtn', { count: numDays })}
      </Button>
    );
  };

  const SkipBtn = () => {
    return (
      <Button
        variant="warning"
        onClick={() => {
          const updates: UserSetting[] = wizardSettings.map((s) => ({
            settingId: s.settingId,
            settingBoolValue: true,
          }));
          bulkUpdate(updates);
        }}
      >
        {t('messages.tutorial.skipBtn')}
      </Button>
    );
  };

  if (wizardSettings.every((setting) => setting.settingBoolValue === false) && delayDateString === '') {
    return (
      <Modal show centered>
        <Modal.Header>
          <Modal.Title>{t('messages.tutorial.modal.header')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <MDPreview content={t('messages.tutorial.modal.body')} className="mb-4" />

          <Stack gap={2}>
            <NavLink
              to="/tutorial"
              className="btn btn-primary"
              onClick={() => setTheDelayDate(DateTime.now().plus({ days: 1 }))}
            >
              {t('messages.tutorial.modal.takeItBtn')}
            </NavLink>

            <DelayBtn numDays={1} />

            <DelayBtn numDays={5} />

            <SkipBtn />
          </Stack>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col xs="12" className="bg-info-subtle m-1 p-1">
          <MDPreview content={t('messages.tutorial.inline.body')} className="mb-3" />

          <Stack gap={2} direction="horizontal">
            <NavLink
              to="/tutorial"
              className="btn btn-primary"
              onClick={() => setTheDelayDate(DateTime.now().plus({ days: 1 }))}
            >
              {t('messages.tutorial.inline.nowBtn')}
            </NavLink>

            <DelayBtn numDays={3} />

            <DelayBtn numDays={5} />

            <SkipBtn />
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};
