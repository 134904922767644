import React, { useState } from 'react';
import { TutorialData } from '../../../dm/tutorial.js';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { TabTitle } from './helpers/TabTitle.js';
import { TutorialSection } from './TutorialSection.js';
import { TutorialComplete } from './TutorialComplete.js';
import { useTranslation } from 'react-i18next';

interface ITutorial {
  tutorialData: TutorialData;
  sectionCompleteCallback(sectionName: string): void;
  noSettings?: boolean;
}
export const Tutorial = ({ tutorialData, sectionCompleteCallback, noSettings = false }: ITutorial) => {
  const { t } = useTranslation(['translation', tutorialData.i18nNS]);

  const getNextTab = (pageName?: string) => {
    const sectionsToUse = tutorialData.sections.filter((section) => section.i18nSection !== pageName);

    for (const section of sectionsToUse) {
      if (!section.complete && section.i18nSection !== pageName) return section.i18nSection;
    }

    return sectionsToUse[0].i18nSection;
  };

  const [currentPage, setCurrentPage] = useState(getNextTab());

  const allPagesCompleted = tutorialData.sections.every((section) => section.complete);

  if (tutorialData.sections.length === 1) {
    return (
      <TutorialSection
        i18NS={tutorialData.i18nNS}
        i18nLocation={tutorialData.sections[0].i18nSection}
        markComplete={() => sectionCompleteCallback(tutorialData.sections[0].settingName)}
        subSections={tutorialData.sections[0].subsections}
        noSettings={noSettings}
      />
    );
  }

  return (
    <Container fluid>
      {!allPagesCompleted && (
        <Tabs id="tutorial-content" activeKey={currentPage} onSelect={(e) => setCurrentPage(e as string)}>
          {tutorialData.sections.map((section) => (
            <Tab
              key={`tutorial-${tutorialData.i18nNS}-${section.i18nSection}`}
              eventKey={section.i18nSection}
              title={
                <TabTitle
                  tabName={t(`${tutorialData.i18nNS}:${section.i18nSection}.tabName`)}
                  completed={section.complete || false}
                  isSelected={section.i18nSection === currentPage}
                />
              }
            >
              <TutorialSection
                i18NS={tutorialData.i18nNS}
                i18nLocation={section.i18nSection}
                markComplete={() => sectionCompleteCallback(section.settingName)}
                subSections={section.subsections}
                noSettings={noSettings}
              />
            </Tab>
          ))}
        </Tabs>
      )}

      {allPagesCompleted && <TutorialComplete tutorialData={tutorialData} />}
    </Container>
  );
};
