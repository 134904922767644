import { RefObject } from 'react';
import { TextAreaTextApi, TextState, getStateFromTextArea, selectWord } from './TextAreaTextApi.js';

/**
 * Helper function to replace lines starting with double spaces with
 * properly formatted `>` delimiters. That is:
 *
 * ```
 * > text
 * >  text
 * >    text
 * ```
 *
 * would be replaced with
 *
 * ```
 * > text
 * > > text
 * > > > text
 * ```
 *
 * Had to be designed as a **recursive** function, since the JS
 * `replaceAll` function doesn't work recursively. This feels like
 * a hack, why *wouldn't* `replaceAll` work? But for now, it is
 * what it is.
 *
 * @param origString String to be searched
 * @returns Modified string with double spaces replaced accordingly
 */
export const replaceDoubleSpacesAtBeg = (origString: string): string => {
  const newString = origString.replaceAll(/> {2}( )?/gm, '> > ');

  if (newString === origString) return origString;

  return replaceDoubleSpacesAtBeg(newString);
};

export const replaceTextWith = (
  state: TextState,
  api: TextAreaTextApi,
  token: string,
  endToken: string | undefined = undefined,
  defaultText: string | undefined = undefined,
  makeUpper: boolean = false
): void => {
  const closingToken = endToken || token;

  let newText = defaultText ? defaultText : '';
  if (state.selectedText.length > 0) {
    newText = state.selectedText;
    if (makeUpper) {
      newText = newText.toLocaleUpperCase();
    }
  }

  const startStartPosition = state.selection.start - token.length;
  const endEndPosition = state.selection.end + closingToken.length;
  const beforeText = state.text.substring(startStartPosition, state.selection.start);
  const afterText = state.text.substring(state.selection.end, endEndPosition);

  if (beforeText === token && afterText === closingToken) {
    const oldSelectedText = state.selectedText;
    api.setSelectionRange({
      start: startStartPosition,
      end: endEndPosition,
    });
    api.replaceSelection(oldSelectedText);
    api.setSelectionRange({
      start: state.selection.start - token.length,
      end: state.selection.end - closingToken.length,
    });
    return;
  }

  const newSelectionRange = selectWord({ text: state.text, selection: state.selection });
  const state1 = api.setSelectionRange(newSelectionRange);
  const modifyText = token + newText + closingToken;
  const state2 = api.replaceSelection(modifyText);

  api.setSelectionRange({
    start: state2.selection.end - closingToken.length - state1.selectedText.length,
    end: state2.selection.end - closingToken.length,
  });
};

export const insertText = (state: TextState, api: TextAreaTextApi, text: string): void => {
  api.replaceSelection(text);
};

export const getObjectsFromTextArea = (ta: RefObject<HTMLTextAreaElement>): [TextState, TextAreaTextApi] => {
  const state = getStateFromTextArea(ta.current!);
  const api = new TextAreaTextApi(ta.current!);

  return [state, api];
};
