import React from 'react';
import { ActionEntry, ActionType } from '@soulhx/fs-common';
import { ListGroup, Stack } from 'react-bootstrap';
import { Circle, CircleFill } from 'react-bootstrap-icons';
import { CompletedItem } from './CompletedItem.js';
import { useTranslation } from 'react-i18next';

interface IActionCheckItem {
  item: ActionType;
  clickFunction(id: string): void;
  showDetails: boolean;
  completedEntries: ActionEntry[];
}
export const ActionCheckItem = ({ item, clickFunction, showDetails, completedEntries }: IActionCheckItem) => {
  const { t } = useTranslation(['do']);

  return (
    <ListGroup.Item className="border-0 m-1 p-0">
      <Stack direction="horizontal" gap={1} className="btn p-0" key={item.id} onClick={() => clickFunction(item.id!)}>
        {completedEntries.length > 0 ? <CircleFill className="text-primary" /> : <Circle className="text-primary" />}

        <div className="user-select-none text-start">{item.displayName}</div>

        {!showDetails && completedEntries.length > 1 && (
          <small className="text-secondary">
            {t('do:actionsWidget.numActionsAvailable', { numActions: completedEntries.length })}
          </small>
        )}
      </Stack>
      {showDetails && completedEntries.length > 0 && (
        <ul className="mb-2">
          {completedEntries.map((item) => (
            <CompletedItem key={item.id} item={item} />
          ))}
        </ul>
      )}
    </ListGroup.Item>
  );
};
