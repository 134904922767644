import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ActionEntry,
  ActionType,
  BaseActionEntry,
  DefaultActionType,
  SpecialActionTypeIds,
  UpdateActionEntry,
} from '@soulhx/fs-common';
import { providesList } from './helpers/rtk-helpers.js';
import { customFetchBase } from './helpers/customFetchBase.js';

export interface GetActionsForMonthInterface {
  year: string;
  month: string;
}

export interface GetActionsForDayInterface extends GetActionsForMonthInterface {
  day: string;
}

export interface BaseResponse {
  success: boolean;
}

const clearAtCaches = async () => {
  const atCache = await caches.open('actionTypes');
  let keys = await atCache.keys();
  for (const k of keys) {
    await atCache.delete(k.url);
  }
  const defCache = await caches.open('defAT');
  keys = await defCache.keys();
  for (const k of keys) {
    await defCache.delete(k.url);
  }
};

const clearEntriesCache = async () => {
  const cache = await caches.open('actionEntries');
  const keys = await cache.keys();

  for (const k of keys) {
    await cache.delete(k.url);
  }
};

export const actionApi = createApi({
  reducerPath: 'action',
  baseQuery: customFetchBase,
  tagTypes: ['actionTypes', 'userActions', 'actionEntries', 'specialActionTypes', 'defaultActionTypes'],
  endpoints: (builder) => ({
    getSpecialTypeIDs: builder.query<SpecialActionTypeIds, void>({
      query: () => '/do/t/specialTypes',
      providesTags: ['specialActionTypes'],
    }),
    getActionsForDay: builder.query<ActionEntry[], GetActionsForDayInterface>({
      query: (inputs) => `/do/e/${inputs.year}/${inputs.month}/${inputs.day}`,
      providesTags: (result) => providesList(result, 'actionEntries'),
    }),
    getActionsForMonth: builder.query<ActionEntry[], GetActionsForMonthInterface>({
      query: (inputs) => `/do/e/${inputs.year}/${inputs.month}`,
      providesTags: (result) => providesList(result, 'actionEntries'),
    }),
    createEntry: builder.mutation<ActionEntry, BaseActionEntry>({
      query: (ae) => ({
        url: '/do/e',
        method: 'POST',
        body: ae,
      }),
      invalidatesTags: (result) => (result ? providesList([result!], 'actionEntries') : []),
      onQueryStarted: clearEntriesCache,
    }),
    deleteActionEntry: builder.mutation<BaseResponse, string>({
      query: (id) => ({ url: `/do/e/${id}`, method: 'DELETE' }),
      invalidatesTags: ['actionEntries'],
      onQueryStarted: clearEntriesCache,
    }),
    updateActionEntry: builder.mutation<ActionEntry, UpdateActionEntry>({
      query: (entry) => ({
        url: `/do/e/${entry.id}`,
        method: 'PUT',
        body: entry,
      }),
      invalidatesTags: (result) => (result ? providesList([result!], 'actionEntries') : []),
      onQueryStarted: clearEntriesCache,
    }),
    getActionTypes: builder.query<ActionType[], void>({
      query: () => '/do/t',
      providesTags: (result) => providesList(result, 'actionTypes'),
    }),
    getDefaultActionTypes: builder.query<DefaultActionType[], void>({
      query: () => '/do/t/defaults',
      providesTags: ['defaultActionTypes'],
    }),
    createOrUpdateActionType: builder.mutation<ActionType, ActionType>({
      query: (at) => ({
        url: '/do/t',
        method: 'POST',
        body: at,
      }),
      invalidatesTags: ['actionTypes', 'defaultActionTypes'],
      onQueryStarted: clearAtCaches,
    }),
    deleteActionType: builder.mutation<BaseResponse, string>({
      query: (id) => ({ url: `/do/t/${id}`, method: 'DELETE' }),
      invalidatesTags: ['actionTypes', 'defaultActionTypes'],
      onQueryStarted: clearAtCaches,
    }),
  }),
});

export const {
  useGetActionTypesQuery,
  useCreateOrUpdateActionTypeMutation,
  useGetActionsForDayQuery,
  useGetActionsForMonthQuery,
  useCreateEntryMutation,
  useDeleteActionEntryMutation,
  useUpdateActionEntryMutation,
  useGetSpecialTypeIDsQuery,
  useDeleteActionTypeMutation,
  useGetDefaultActionTypesQuery,
} = actionApi;
