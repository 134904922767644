import React from 'react';
import { TutorialData } from '../../../dm/tutorial.js';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { UnCompleteSectionWidget } from './helpers/UnCompleteSectionWidget.js';
import { NavLink } from 'react-router-dom';
import { MDPreview } from '../md/index.js';

interface ITutorialComplete {
  tutorialData: TutorialData;
}
export const TutorialComplete = ({ tutorialData }: ITutorialComplete) => {
  const { t } = useTranslation();

  return (
    <Alert variant="success">
      <Alert.Heading>{t('tutorials.success.title')}</Alert.Heading>

      <MDPreview content={t('tutorials.success.message')} className="mb-3" />

      <UnCompleteSectionWidget tuturialData={tutorialData} />

      <NavLink to="/" className="mt-4 alert-link">
        {t('tutorials.success.goHome')}
      </NavLink>
    </Alert>
  );
};
