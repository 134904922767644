import { SearchNoteParams } from '../dm/Note.js';

/**
 * Helper function to take in a `SearchNoteParams` object and
 * return a URL with query params to call the API. Includes
 * "full" and "partial" options for whether it should be a
 * relative URL or a full one.
 *
 * @param searchParams Search params
 * @param full Whether or not this should be a full or relative URL
 * @returns String for the URL to call the 'search notes' API
 */
export const getAllNotesUrlFromParams = (searchParams: SearchNoteParams, full = true): string => {
  const urlParts: string[] = [];
  let prefix = '?';

  if (full) {
    urlParts.push('/api');
  }
  urlParts.push('/read/n/');

  if (searchParams.page) {
    urlParts.push(prefix, 'p=', searchParams.page.toString());
    prefix = '&';
  }

  if (searchParams.osis) {
    urlParts.push(prefix, 'o=', searchParams.osis);
    prefix = '&';
  }

  if (searchParams.sortOrder) {
    urlParts.push(prefix, 's=', searchParams.sortOrder);
    prefix = '&';
  }

  if (searchParams.searchText) {
    urlParts.push(prefix, 'q=', searchParams.searchText);
    prefix = '&';
  }

  return urlParts.join('').replace(/\s/g, '+');
};
