import React, { ReactNode } from 'react';

interface IExampleContainer {
  children: ReactNode;
}
export const ExampleContainer = ({ children }: IExampleContainer) => {
  return (
    <div style={{ maxWidth: '750px' }} className="mx-1 mx-sm-2 mx-md-auto mb-3">
      {children}
    </div>
  );
};
