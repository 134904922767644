import React, { ReactNode } from 'react';
import { getBGUrl } from '../../../hooks/md/extensions/bibleLink.js';
import { getFormattedRef } from '@soulhx/fs-common';

interface IBibleLink {
  passage: string;
  includeVerses?: boolean;
  context?: string;
  version?: string;
  hideVersion?: boolean;
  disabled?: boolean;
  children?: ReactNode;
}
export const BibleLink = ({
  passage,
  children,
  context,
  version,
  includeVerses = true,
  hideVersion = false,
  disabled = false,
}: IBibleLink) => {
  if (disabled) return <></>;

  let title = passage;
  if (version && !hideVersion) title += ` (${version})`;

  return (
    <a title={title} href={getBGUrl(passage, { context, includeVerses, version })} target="_blank" rel="noreferrer">
      {children || getFormattedRef(passage, { includeVerses, context })}✞
    </a>
  );
};
