import React from 'react';
import { Col, Placeholder, Row } from 'react-bootstrap';
import { Circle, Check2Circle } from 'react-bootstrap-icons';

export const ActionWidgetFormPlaceholder = () => {
  return (
    <>
      <Row>
        <Col xs="1">
          <Circle className="placeholder" />
        </Col>
        <Placeholder as={Col} xs="10">
          <Placeholder xs="10" />
        </Placeholder>
      </Row>
      <Row>
        <Col xs="1">
          <Check2Circle className="placeholder" />
        </Col>
        <Placeholder as={Col} xs="10">
          <Placeholder xs="10" />
        </Placeholder>
      </Row>
      <Row>
        <Col xs="1">
          <Circle className="placeholder" />
        </Col>
        <Placeholder as={Col} xs="10">
          <Placeholder xs="10" />
        </Placeholder>
      </Row>
    </>
  );
};
