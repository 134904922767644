import React, { useState } from 'react';
import { Alert, Badge, CloseButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { MDPreview } from './md/index.js';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { useUserSetting } from '../../hooks/Setting.js';
import { useHelpTextAllowed } from '../../hooks/AllowHelpText.js';
import { SHXButton } from './ui/buttons/index.js';

interface IShxHelpText {
  pageName: string;
  textName: string;
  className?: string;
  collapsed?: boolean;
  size?: number;
  inline?: boolean;
  firstLevel?: boolean;
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
}
export const ShxHelpText = ({
  pageName,
  textName,
  className,
  collapsed = false,
  size = 25,
  inline = false,
  firstLevel = false,
  variant = 'info',
}: IShxHelpText) => {
  const { settingValue: showSetting, update: updateVal } = useUserSetting<boolean>(`helpText.${pageName}.${textName}`);
  const { t } = useTranslation(['helpText']);
  const [showHiddenAlert, setShowHiddenAlert] = useState(!collapsed);
  const [forceHide, setForceHide] = useState(false);
  const allowShow = useHelpTextAllowed(`helpText:${pageName}.${textName}`, { firstLevel, skip: !showSetting });

  if (!allowShow) return <></>;

  if (forceHide) return <></>;

  if (!showSetting) return <></>;

  const theAlert = inline ? (
    <Badge bg={`${variant}-subtle`} text="dark">
      <span className="float-end">
        <CloseButton
          onClick={() => {
            updateVal(!showSetting);
            setForceHide(true);
          }}
        />
      </span>
      <MDPreview className="me-3" content={t(`helpText:${pageName}.${textName}`)} />
    </Badge>
  ) : (
    <Alert
      variant={variant}
      className={`shx-helptext ${className || ''}`}
      dismissible
      onClose={() => {
        updateVal(!showSetting);
        setForceHide(true);
      }}
    >
      <MDPreview content={t(`helpText:${pageName}.${textName}`)} />
    </Alert>
  );

  if (!collapsed) return theAlert;

  return (
    <>
      {showHiddenAlert && theAlert}
      <SHXButton variant={`outline-${variant}`} onClick={() => setShowHiddenAlert(!showHiddenAlert)}>
        <InfoCircleFill height={size} width={size} />
      </SHXButton>
    </>
  );
};
