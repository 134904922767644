/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { parse, setOptions, use, getDefaults } from 'marked';
import { FootnoteRefExtension } from './extensions/footnotes.js';
import { HighlightExtension } from './extensions/highlight.js';
import { AllUpperExtension } from './extensions/allUpper.js';
import { SmallCapsExtension } from './extensions/smallcaps.js';
import { EraExtension } from './extensions/era.js';
import { BibleLinkExtension } from './extensions/bibleLink.js';
import { SuperscriptExtension } from './extensions/superscript.js';
import { markedXhtml } from 'marked-xhtml';
import { markedSmartypants } from 'marked-smartypants';
import { BlockQuoteExtension } from './extensions/blockquote.js';

const fnRE = /^\[\^([^\]]+)\]: /;

export interface HtmlFromMdOptions {
  /**
   * Default version of the Bible to use for links (when not specified in the MD)
   */
  defaultVersion?: string;

  /**
   * Context of the Scripture to use in creating links (if any)
   */
  scriptureContext?: string;

  /**
   * Whether to format passages including trailing verses when not necessary.
   *
   * @example
   * // returns `John 1:1-51`
   * function('John 1:1-51', { includeVerses: true })
   *
   * @example
   * // returns `John 1`
   * function('John 1:1-51', { includeVerses: false })
   */
  includeVerses?: boolean;

  /**
   * Whether to return the minimal version of the HTML
   */
  minimal?: boolean;
}

/**
 * Function to take Markdown (in SoulHX format) and render it to a
 * formatted (HTML) output. The `<MarkdownBox>` component would be
 * easier to use (which uses the hook version under the covers), but for
 * cases where the "raw" HTML is needed this function is exposed
 * for direct use.
 *
 * Handles all "standard" GitHub Markdown syntax as supported by
 * **marked**, along with some SHX-specific extensions.
 *
 * @param md Markdown to parse
 * @param options Optional set of options to include
 * @returns Formatted HTML
 */
export const getHtmlFromMD = (
  md: string,
  {
    defaultVersion = undefined,
    scriptureContext = undefined,
    includeVerses = false,
    minimal = false,
  }: HtmlFromMdOptions = {}
): string => {
  setOptions(getDefaults());

  if (minimal) {
    use({
      pedantic: false,
      gfm: false,
      breaks: false,
      silent: true,
      useNewRenderer: true,
      renderer: {
        link({ href, title, tokens }) {
          const renderedTitle = title ? `title="${title}"` : '';
          return `<a href="${href}" ${renderedTitle} target="_blank">${this.parser.parseInline(tokens)}</a>`;
        },
      },
      extensions: [
        HighlightExtension,
        AllUpperExtension,
        SmallCapsExtension,
        EraExtension,
        BibleLinkExtension(defaultVersion, scriptureContext, includeVerses),
        SuperscriptExtension,
      ],
    });
    use(markedSmartypants() as any);
    use(markedXhtml() as any);

    const html = parse(md, { async: false }) as string;
    return html.substring(3, html.length - 5);
  }

  use(markedXhtml() as any);
  use({
    pedantic: false,
    gfm: true,
    breaks: true,
    silent: true,
    useNewRenderer: true,
    renderer: {
      paragraph({ text, tokens }) {
        const fnMatch = fnRE.exec(text);

        if (fnMatch) {
          const responseStrings: string[] = [];

          responseStrings.push('<ul>', '<li>');

          const parsedText = this.parser.parseInline(tokens);
          responseStrings.push(parsedText.replace(fnMatch[0], ''));

          responseStrings.push(
            ' <a href="#user-content-fnref-',
            fnMatch[1],
            '" id="user-content-fn-',
            fnMatch[1],
            '">↩</a>'
          );
          responseStrings.push('</li>', '</ul>');

          const returnString = responseStrings.join('');
          return returnString;
        }

        return '<p>' + this.parser.parseInline(tokens) + '</p>';
      },
      link({ href, title, tokens }) {
        const renderedTitle = title ? `title="${title}"` : '';
        return `<a href="${href}" ${renderedTitle} target="_blank">${this.parser.parseInline(tokens)}</a>`;
      },
    },
    extensions: [
      FootnoteRefExtension,
      HighlightExtension,
      AllUpperExtension,
      SmallCapsExtension,
      EraExtension,
      BibleLinkExtension(defaultVersion, scriptureContext, includeVerses),
      SuperscriptExtension,
      BlockQuoteExtension(scriptureContext),
    ],
  });
  use(markedSmartypants() as any);

  return parse(md, { async: false }) as string;
};

/**
 * Hook version of the `getHTMLFromMD` function; simply calls the
 * other function under the covers, and memoizes it.
 *
 * @param md Markdown to parse
 * @param options Optional set of options to include
 * @returns Formatted HTML
 */
export const useHtmlFromMD = (
  md: string,
  {
    defaultVersion = undefined,
    scriptureContext = undefined,
    includeVerses = false,
    minimal = false,
  }: HtmlFromMdOptions = {}
): string => {
  const htmlResponse = useMemo(
    () => getHtmlFromMD(md, { defaultVersion, scriptureContext, includeVerses, minimal }),
    [md, defaultVersion, scriptureContext, includeVerses, minimal]
  );

  return htmlResponse;
};
