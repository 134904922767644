import React from 'react';
import { CalendarDate, FolderMinus, FolderPlus, FolderSymlink, TextParagraph } from 'react-bootstrap-icons';
import { IIconProps, IconProps } from '../../../../dm/icons.js';

const PrayerBodyIcon = ({ size, color, className }: IIconProps) => {
  return <TextParagraph height={size} width={size} fill={color} className={className} />;
};

const PrayerTargetDateIcon = ({ size, color, className }: IIconProps) => {
  return <CalendarDate height={size} width={size} fill={color} className={className} />;
};

const DetatchItem = ({ size, color, className }: IIconProps) => {
  return <FolderMinus height={size} width={size} fill={color} className={className} />;
};

const AttachItem = ({ size, color, className }: IIconProps) => {
  return <FolderPlus height={size} width={size} fill={color} className={className} />;
};

const MoveListItem = ({ size, color, className }: IIconProps) => {
  return <FolderSymlink height={size} width={size} fill={color} className={className} />;
};

interface IPrayerIcons extends IconProps {
  type: 'body' | 'targetdate' | 'detatch' | 'attach' | 'move';
}
export const PrayerIcons = ({ type, size = '1em', color = 'currentColor', className }: IPrayerIcons) => {
  const props: IIconProps = { size, color, className };

  switch (type) {
    case 'body': {
      return PrayerBodyIcon(props);
    }
    case 'targetdate': {
      return PrayerTargetDateIcon(props);
    }
    case 'attach': {
      return AttachItem(props);
    }
    case 'detatch': {
      return DetatchItem(props);
    }
    case 'move': {
      return MoveListItem(props);
    }
  }
};
