import React from 'react';
import { selectWord, getBreaksNeededForEmptyLineAfter, getBreaksNeededForEmptyLineBefore } from './TextAreaTextApi.js';
import {
  TypeBold,
  TypeItalic,
  TypeH1,
  TypeH2,
  TypeH3,
  Link45deg,
  Quote,
  ListUl,
  ListOl,
  BookHalf,
  BookFill,
  Table,
} from 'react-bootstrap-icons';
import { BaseUserMDTemplate } from '@soulhx/fs-common';
import i18n from '../../../../i18n.js';
import { MDToolbar, MDToolbarButton, MDToolbarButtonGroup } from '../../../../dm/md.js';
import {
  getObjectsFromTextArea,
  insertText,
  replaceDoubleSpacesAtBeg,
  replaceTextWith,
} from './command-helper-functions.js';

/**
 * Returns the standard toolbar, with or without optional user-
 * defined **templates**. If no templates are passed, the default
 * toolbar is returned, but if templates are included extra buttons
 * are appended to the end to insert those templates.
 *
 * @param templates Optional set of templates for which to create buttons
 * @returns Toolbar object tree (which can be rendered into components)
 */
export const getToolbar = (templates?: BaseUserMDTemplate[], fullScreen = false): MDToolbar => {
  const fullToolbar: MDToolbar = {
    groups: [
      {
        buttons: [
          {
            name: i18n.t('buttonBar.bold', { ns: 'mde' }),
            keyboardShortcut: 'Control+b',
            buttonContents: <TypeBold />,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '**');
            },
            showInMini: true,
          },
          {
            name: i18n.t('buttonBar.italics', { ns: 'mde' }),
            keyboardShortcut: 'Control+i',
            buttonContents: <TypeItalic />,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '*');
            },
            showInMini: true,
          },
          {
            name: i18n.t('buttonBar.h1', { ns: 'mde' }),
            buttonContents: <TypeH1 />,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '# ', ' ', i18n.t('buttonBar.headingPlaceholder', { ns: 'mde' }));
            },
            showInMini: false,
          },
          {
            name: i18n.t('buttonBar.h2', { ns: 'mde' }),
            buttonContents: <TypeH2 />,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '## ', ' ', i18n.t('buttonBar.headingPlaceholder', { ns: 'mde' }));
            },
            showInMini: false,
          },
          {
            name: i18n.t('buttonBar.h3', { ns: 'mde' }),
            buttonContents: <TypeH3 />,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '### ', ' ', i18n.t('buttonBar.headingPlaceholder', { ns: 'mde' }));
            },
            showInMini: false,
          },
        ],
      },
      {
        buttons: [
          {
            name: i18n.t('buttonBar.link', { ns: 'mde' }),
            keyboardShortcut: 'Control+Shift+k',
            buttonContents: <Link45deg />,
            execute(textBox) {
              const linkURL = prompt(i18n.t('buttonBar.pleaseEnterUrl', { ns: 'mde' }));
              if (!linkURL) {
                return;
              }

              const [state, api] = getObjectsFromTextArea(textBox);
              const modifyText = `[${state.selectedText}](${linkURL})`;
              api.replaceSelection(modifyText);
            },
            showInMini: true,
          },
          {
            name: i18n.t('buttonBar.quote', { ns: 'mde' }),
            showInMini: true,
            keyboardShortcut: 'Control+q',
            buttonContents: <Quote />,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              const selectedText = state.selectedText
                ? state.selectedText
                : i18n.t('buttonBar.scriptureQuotationPlaceholder', { ns: 'mde' });
              const newSelectionRange = selectWord({ text: state.text, selection: state.selection });
              const state1 = api.setSelectionRange(newSelectionRange);
              const breaksBeforeCount = getBreaksNeededForEmptyLineBefore(state1.text, state1.selection.start);
              const breaksBefore = Array(breaksBeforeCount + 1).join('\n');

              const breaksAfterCount = getBreaksNeededForEmptyLineAfter(state1.text, state1.selection.end);
              const breaksAfter = Array(breaksAfterCount + 1).join('\n');

              let newText = '> ' + selectedText.replaceAll('\n', '\n> ');
              newText = replaceDoubleSpacesAtBeg(newText);
              newText = newText.replaceAll(/^(\d+)\s/g, ' ^$1^ ');
              newText = newText.replaceAll(/\s(\d+)\s/g, ' ^$1^ ');

              const capitalizedWordsRE = /\b([\p{Lu}\p{Lt}]{2,})\b/gu;
              newText = newText.replaceAll(capitalizedWordsRE, '^^^$1^^^');

              //replaces the current selection with the scripture quote mark
              api.replaceSelection(`${breaksBefore}${newText}${breaksAfter}`);

              const selectionStart = state1.selection.start + breaksBeforeCount;
              const selectionEnd = selectionStart + selectedText.length + (newText.length - selectedText.length);

              api.setSelectionRange({
                start: selectionStart,
                end: selectionEnd,
              });
            },
          },
        ],
      },
      {
        buttons: [
          {
            name: i18n.t('buttonBar.bullets', { ns: 'mde' }),
            buttonContents: <ListUl />,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '* ', ' ');
            },
            showInMini: true,
          },
          {
            name: i18n.t('buttonBar.numberedList', { ns: 'mde' }),
            buttonContents: <ListOl />,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '1. ', ' ');
            },
            showInMini: true,
          },
          {
            name: i18n.t('buttonBar.table.name', { ns: 'mde' }),
            buttonContents: <Table />,
            showInMini: false,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              let openToken = `| ${i18n.t('buttonBar.table.headingPlaceholder', { ns: 'mde' })} | ${i18n.t(
                'buttonBar.table.headingPlaceholder',
                { ns: 'mde' }
              )} |
| -- | -- |
| `;
              let closeToken = ` |  |

`;

              if (state.selectedText.includes('\n')) {
                openToken = `| ${i18n.t('buttonBar.table.headingPlaceholder', { ns: 'mde' })} | ${i18n.t(
                  'buttonBar.table.headingPlaceholder',
                  { ns: 'mde' }
                )} |
| -- | -- |
|  |  |

`;
                closeToken = ' ';
              }

              replaceTextWith(state, api, openToken, closeToken);
            },
          },
        ],
      },
      {
        buttons: [
          {
            name: i18n.t('buttonBar.doubleQuotes', { ns: 'mde' }),
            buttonContents: <span>“a”</span>,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(
                state,
                api,
                i18n.t('buttonBar.openDoubleQuote', { ns: 'mde' }),
                i18n.t('buttonBar.endDoubleQuote', { ns: 'mde' })
              );
            },
            showInMini: false,
          },
          {
            name: i18n.t('buttonBar.singleQuotes', { ns: 'mde' }),
            buttonContents: <span>‘a’</span>,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(
                state,
                api,
                i18n.t('buttonBar.openSingleQuote', { ns: 'mde' }),
                i18n.t('buttonBar.endSingleQuote', { ns: 'mde' })
              );
            },
            showInMini: false,
          },
          {
            name: i18n.t('buttonBar.highlight', { ns: 'mde' }),
            keyboardShortcut: 'Control+Shift+H',
            buttonContents: <mark>abc</mark>,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '==', '==', i18n.t('buttonBar.highlightPlaceholder', { ns: 'mde' }));
            },
            showInMini: true,
          },
          {
            name: i18n.t('buttonBar.superscript', { ns: 'mde' }),
            buttonContents: (
              <span>
                2<sup>2</sup>
              </span>
            ),
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '^');
            },
            showInMini: false,
          },
          {
            name: i18n.t('buttonBar.allUpper', { ns: 'mde' }),
            keyboardShortcut: 'Alt+q',
            buttonContents: (
              <b>
                L<span style={{ fontVariant: 'small-caps' }}>ord</span>
              </b>
            ),
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '^^^', '^^^', i18n.t('buttonBar.allUpperPlaceholder', { ns: 'mde' }), true);
            },
            showInMini: true,
          },
          {
            name: i18n.t('buttonBar.smallcaps', { ns: 'mde' }),
            buttonContents: <span style={{ fontVariant: 'small-caps' }}>SmCa</span>,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '^^^^');
            },
            showInMini: false,
          },
          {
            name: i18n.t('buttonBar.era', { ns: 'mde' }),
            buttonContents: <span style={{ fontVariant: 'small-caps' }}>b.c.</span>,
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '^^', undefined, undefined, true);
            },
            showInMini: false,
          },
        ],
      },
      {
        buttons: [
          {
            name: i18n.t('buttonBar.scriptureLink', { ns: 'mde' }),
            keyboardShortcut: 'Control+Shift+S',
            buttonContents: (
              <>
                <BookHalf />
                <Link45deg />
              </>
            ),
            execute(textBox) {
              const [state, api] = getObjectsFromTextArea(textBox);
              replaceTextWith(state, api, '[|', '|]', i18n.t('buttonBar.scriptureLinkPlaceholder', { ns: 'mde' }));
            },
            showInMini: true,
          },
          {
            name: i18n.t('buttonBar.customScriptureLink', { ns: 'mde' }),
            keyboardShortcut: 'Alt+s',
            buttonContents: (
              <>
                <BookFill />
                <Link45deg />
              </>
            ),
            execute(textBox) {
              const customText = prompt(i18n.t('buttonBar.customScriptureLinkPrompt', { ns: 'mde' }));
              if (!customText) {
                return;
              }

              const [state, api] = getObjectsFromTextArea(textBox);
              const modifyText = `[|${
                state.selectedText || i18n.t('buttonBar.customScriptureLinkPlaceholder', { ns: 'mde' })
              } (${customText})|]`;
              api.replaceSelection(modifyText);
            },
            showInMini: false,
          },
        ],
      },
    ],
  };

  const toolbar: MDToolbar = { groups: [] };

  for (const g of fullToolbar.groups) {
    const filteredButtons = g.buttons.filter((b) => fullScreen || b.showInMini);
    if (filteredButtons.length > 0) {
      toolbar.groups.push({ buttons: filteredButtons });
    }
  }

  if (!templates) return toolbar;

  const newTB: MDToolbar = { groups: [] };

  for (const g of toolbar.groups) {
    const newGroup: MDToolbarButtonGroup = { buttons: [] };
    for (const b of g.buttons) {
      const newButton: MDToolbarButton = {
        buttonContents: b.buttonContents,
        execute: b.execute,
        name: b.name,
        keyboardShortcut: b.keyboardShortcut,
        showInMini: b.showInMini,
      };
      newGroup.buttons.push(newButton);
    }

    newTB.groups.push(newGroup);
  }

  const group: MDToolbarButtonGroup = { buttons: [] };
  for (const t of templates) {
    group.buttons.push({
      name: t.name,
      buttonContents: <span>{t.name}</span>,
      execute(textBox) {
        const [state, api] = getObjectsFromTextArea(textBox);
        insertText(state, api, t.template);
      },
      showInMini: true,
    });
  }

  newTB.groups.push(group);

  return newTB;
};
