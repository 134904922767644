import { RendererExtension, TokenizerExtension } from 'marked';

export const SmallCapsExtension: TokenizerExtension | RendererExtension = {
  name: 'SmallCapsExtension',
  level: 'inline',
  start(src) {
    return src.match(/\^\^\^\^/)?.index || -1;
  },
  tokenizer(src) {
    const rule = /^\^\^\^\^([^^]+)\^\^\^\^/;
    const match = rule.exec(src);
    if (!match) {
      return;
    }

    const token = {
      type: 'SmallCapsExtension',
      raw: match[0],
      scText: match[1],
      tokens: [],
    };
    this.lexer.inline(token.scText, token.tokens);
    return token;
  },
  renderer(token) {
    return `<span style="font-variant: small-caps;">${this.parser.parseInline(token.tokens || token.scText)}</span>`;
  },
};
