import React from 'react';
import { BaseUserMDTemplate, UserMDTemplate } from '@soulhx/fs-common';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { Form } from 'react-bootstrap';
import {
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
} from '../../../services/TemplatesService.js';
import { useTranslation } from 'react-i18next';
import { StandardButton } from '../ui/buttons/index.js';

const schema = yup.object({
  name: yup.string().required('templateEditor.fields.name.invalid'),
  template: yup.string().required('templateEditor.fields.template.invalid'),
});
type ValuesSchema = yup.InferType<typeof schema>;

interface ITemplate {
  template?: UserMDTemplate;
}
export const Template = ({ template }: ITemplate) => {
  const [submitNewTemplateToApi] = useCreateTemplateMutation();
  const [saveExistingTemplateToApi] = useUpdateTemplateMutation();
  const [deleteFromApi] = useDeleteTemplateMutation();
  const { t } = useTranslation();

  const initialValues: ValuesSchema = template
    ? { name: template.name, template: template.template }
    : { name: '', template: '' };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values, { resetForm }) => {
        if (template) {
          const templateUpdate: UserMDTemplate = {
            id: template.id,
            name: values.name,
            template: values.template,
          };
          await saveExistingTemplateToApi(templateUpdate).unwrap();
        } else {
          const newTemplate: BaseUserMDTemplate = {
            name: values.name,
            template: values.template,
          };
          await submitNewTemplateToApi(newTemplate).unwrap();
        }

        resetForm();
      }}
      validateOnBlur
      validateOnChange
      enableReinitialize
      validateOnMount
    >
      {(fp: FormikProps<ValuesSchema>) => (
        <Form noValidate onSubmit={fp.handleSubmit}>
          <h4>{template ? template.name : t('templateEditor.newTitle')}</h4>

          <Form.Group className="mb-2">
            <Form.Label>{t('templateEditor.fields.name.label')}</Form.Label>
            <Form.Control
              id="name"
              name="name"
              value={fp.values.name}
              onChange={fp.handleChange}
              onBlur={fp.handleBlur}
              type="text"
              placeholder={t('templateEditor.fields.name.placeholder')}
              isInvalid={fp.dirty && !!fp.errors.name}
            />
            <Form.Control.Feedback type="invalid">{t(fp.errors.name!)}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>{t('templateEditor.fields.template.label')}</Form.Label>
            <Form.Control
              id="template"
              name="template"
              value={fp.values.template}
              onChange={fp.handleChange}
              onBlur={fp.handleBlur}
              as="textarea"
              rows={4}
              isInvalid={fp.dirty && !!fp.errors.template}
              className="monospace-text"
            />
            <Form.Control.Feedback type="invalid">{t(fp.errors.template!)}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-2 mb-5">
            <StandardButton type="save" submit disabled={!fp.dirty || !fp.isValid} />

            {template && <StandardButton type="delete" onClick={() => deleteFromApi(template.id)} />}
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};
