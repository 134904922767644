import React, { CSSProperties, ReactElement, ReactNode, useMemo } from 'react';
import { useWindowWidth } from './helpers.js';
import { IMasonry } from './Masonry.js';

const DEFAULT_COLUMNS_COUNT = 1;

interface IResponsiveMemory {
  children: ReactNode;

  columnsCountBreakPoints?: {
    [key: number]: number;
  };

  className?: string;

  style?: CSSProperties;
}
export const ResponsiveMasonry = ({
  children,
  columnsCountBreakPoints = { 350: 1, 750: 2, 900: 3 },
  className,
  style,
}: IResponsiveMemory) => {
  const windowWidth = useWindowWidth();

  const columnsCount = useMemo(() => {
    const breakPoints = Object.keys(columnsCountBreakPoints)
      .sort((a, b) => {
        return parseInt(a) - parseInt(b);
      })
      .map((bp) => parseInt(bp));
    let count = breakPoints.length > 0 ? columnsCountBreakPoints[breakPoints[0]] : DEFAULT_COLUMNS_COUNT;

    breakPoints.forEach((breakPoint) => {
      if (breakPoint < windowWidth) {
        count = columnsCountBreakPoints[breakPoint];
      }
    });

    return count;
  }, [windowWidth, columnsCountBreakPoints]);

  return (
    <div className={className} style={style}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child as ReactElement<IMasonry>, {
          key: index,
          columnsCount: columnsCount,
        })
      )}
    </div>
  );
};
