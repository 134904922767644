import { RendererExtension, TokenizerExtension } from 'marked';

export const EraExtension: TokenizerExtension | RendererExtension = {
  name: 'EraExtension',
  level: 'inline',
  start(src) {
    return src.match(/\^\^/)?.index || -1;
  },
  tokenizer(src) {
    const rule = /^\^\^([^^]+)\^\^/;
    const match = rule.exec(src);
    if (!match) {
      return;
    }

    const token = {
      type: 'EraExtension',
      raw: match[0],
      eraText: match[1],
    };
    return token;
  },
  renderer(token) {
    return `<span style="text-transform: uppercase; font-size: 1.3ex;">${token.eraText}</span>`;
  },
};
