import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AddHtRequest,
  BasePassage,
  Hashtag,
  Passage,
  SearchPassageParams,
  SetHtRequest,
  getPassagesUrlFromParams,
} from '@soulhx/fs-common';
import { providesList } from './helpers/rtk-helpers.js';
import { customFetchBase } from './helpers/customFetchBase.js';

export interface DeletePassageResponse {
  success: boolean;
}

export interface SetTagsForPassageParams {
  passageId: string;
  requestBody: SetHtRequest;
}

export interface AddTagToPassageParams {
  passageId: string;
  requestBody: AddHtRequest;
}

export interface RemoveTagFromPassageParams {
  passageId: string;
  tag: string;
}

export const clearPassageCache = async () => {
  const cache = await caches.open('passages');
  const keys = await cache.keys();

  for (const k of keys) {
    await cache.delete(k.url);
  }
};

export const passageApi = createApi({
  reducerPath: 'passages',
  baseQuery: customFetchBase,
  tagTypes: ['passages'],
  endpoints: (builder) => ({
    getPassages: builder.query<Passage[], SearchPassageParams>({
      query: (params) => getPassagesUrlFromParams(params, false),
      providesTags: (result) => providesList(result, 'passages'),
    }),
    getPassageById: builder.query<Passage, string>({
      query: (passageId) => `/read/p/${passageId}`,
      providesTags: (result) => (result ? [{ type: 'passages', id: result.id }] : []),
    }),
    deletePassage: builder.mutation<DeletePassageResponse, string>({
      query: (passageId) => ({ url: `/read/p/${passageId}`, method: 'DELETE' }),
      invalidatesTags: ['passages'],
      onQueryStarted: clearPassageCache,
    }),
    deleteMultiplePassages: builder.mutation<DeletePassageResponse, string[]>({
      query: (idList) => ({
        url: '/read/p/removeMultiple',
        method: 'POST',
        body: idList,
      }),
      invalidatesTags: ['passages'],
      onQueryStarted: clearPassageCache,
    }),
    updatePassage: builder.mutation<Passage, Passage>({
      query: (psg) => ({
        url: `/read/p/${psg.id}`,
        method: 'PUT',
        body: psg,
      }),
      invalidatesTags: (result) => (result ? [{ type: 'passages', id: result.id }] : []),
      onQueryStarted: clearPassageCache,
    }),
    newPassage: builder.mutation<Passage, BasePassage>({
      query: (passage) => ({
        url: '/read/p/',
        method: 'POST',
        body: passage,
      }),
      invalidatesTags: ['passages'],
      onQueryStarted: clearPassageCache,
    }),
    getPassageHashtags: builder.query<string[], string | undefined>({
      query: (search) => `/read/p/ht${search ? '?q=' + search : ''}`,
      forceRefetch: () => true,
    }),
    setPassageHashtags: builder.mutation<Hashtag[], SetTagsForPassageParams>({
      query: (params) => ({
        url: `/read/p/${params.passageId}/setTags`,
        method: 'POST',
        body: params.requestBody,
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [{ id: params.passageId, type: 'passages' }];
      },
      onQueryStarted: clearPassageCache,
    }),
    addHtToPassage: builder.mutation<Hashtag, AddTagToPassageParams>({
      query: (params) => ({
        url: `/read/p/${params.passageId}/addHt`,
        method: 'POST',
        body: params.requestBody,
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [{ id: params.passageId, type: 'passages' }];
      },
      onQueryStarted: clearPassageCache,
    }),
    removeHtFromPassage: builder.mutation<DeletePassageResponse, RemoveTagFromPassageParams>({
      query: (params) => ({
        url: `/read/p/${params.passageId}/removeHt/${params.tag}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, params) => {
        if (error || !result) return [];

        return [{ id: params.passageId, type: 'passages' }];
      },
      onQueryStarted: clearPassageCache,
    }),
  }),
});

export const {
  useGetPassagesQuery,
  useLazyGetPassagesQuery,
  useGetPassageByIdQuery,
  useDeletePassageMutation,
  useNewPassageMutation,
  useUpdatePassageMutation,
  useSetPassageHashtagsMutation,
  useAddHtToPassageMutation,
  useGetPassageHashtagsQuery,
  useRemoveHtFromPassageMutation,
  useDeleteMultiplePassagesMutation,
} = passageApi;
